<script>
let webServicePath = '/web-service';
let apiDataCenterPath = '/api-datacenter'

const DATA_CENTER = {
  tvConfig: apiDataCenterPath + '/api/configs',
  orderbook: apiDataCenterPath + '/api/{platform}/{kind}/orderbook',
  lastPriceIn24hr: apiDataCenterPath + '/api/{platform}/{kind}/lastpricein24hr',
  markPrices: apiDataCenterPath + '/api/{platform}/{kind}/markprices',
  lastTrades: apiDataCenterPath + '/api/{platform}/{kind}/lasttrades'
}

const WEB_SERVICE = {
  userLogin: webServicePath + '/user/login?forceLogin=true',
  userLoginValidate: webServicePath + '/user/login/validate',
  userLogout: webServicePath + '/user/logout',
  userRegister: webServicePath + '/user/register',
  userGetLoginNonce: webServicePath + '/user/getLoginNonce',
  userGetUnlockNonce: webServicePath + '/user/info/getUnlockNonce',
  userLoginNonceValidate: webServicePath + '/user/validateLoginNonce',
  userGetNonceForBindWallet: webServicePath + '/user/getNonceForBindWallet',
  userValidateNonceForBindWallet: webServicePath + '/user/validateNonceForBindWallet',
  userBindWallet: webServicePath + '/user/bindWallet',
  uiPathPermissionValidate: webServicePath + '/user/permission/access/validate',
  userPermissionQuery: webServicePath + '/user/permission/tradeaccount/query',
  userWebPermissionQuery: webServicePath + '/user/permission/list',
  adminClearLogin: webServicePath + '/admin/user/clearLogin',
  getUserList: webServicePath + '/user/info/list',
  updateUserInfo: webServicePath + '/user/info/update',
  getVerificationCode: webServicePath + '/user/info/sendVerification',
  statistics: webServicePath + '/user/info/statistics',
  updateUserRole: webServicePath + '/management/permission/user/role/update',
  updateRolePermission: webServicePath + '/management/permission/role/update',
  updateWebPermissionConfig: webServicePath + '/management/permission/webPermission/update',
  instanceCreate: webServicePath + '/instance/create',
  instanceUpdateName: webServicePath + '/instance/updateName',
  instanceQuery: webServicePath + '/instance/query',
  instanceBindCustomer: webServicePath + '/instance/bind/customer',
  instanceQueryAllBalance: webServicePath + '/instance/queryAllBalance',
  tradeAccountCreate: webServicePath + '/tradeaccount/create',
  tradeAccountUpdateInfo: webServicePath + '/tradeaccount/updateInfo',
  tradeAccountQuery: webServicePath + '/tradeaccount/query',
  tradeVolQuery: webServicePath + '/trade/vol/query',
  instanceProfitRate: webServicePath + '/instance/profitRate',
  instanceGetAudit: webServicePath + '/instance/operation/audit',
  instanceReset: webServicePath + '/instance/reset',
  instanceForceRefresh: webServicePath + '/instance/forceRefresh',
  instanceLink: webServicePath + '/instance/link',
  instanceUnLink: webServicePath + '/instance/unlink',
  instanceDelete: webServicePath + '/instance/delete',
  rolePermissionList: webServicePath + '/management/permission/role/list',
  platformInfoQuery: webServicePath + '/platform/info/query',
  platformInfoQueryNews: webServicePath + '/platform/info/jinten',
  platformInfoQueryBinanceProduct: webServicePath + '/platform/info/binanceProduct',
  strategyInstanceQueryAllStrategyName: webServicePath + '/strategy/queryAllStrategies',
  strategyInstanceQueryModel: webServicePath + '/strategy/queryModel',
  strategyInstanceCreate: webServicePath + '/strategy/create',
  strategyInstanceQuery: webServicePath + '/strategy/query',
  strategyInstanceQuerySingle: webServicePath + '/strategy/querySingle',
  strategyInstanceReset: webServicePath + '/strategy/reset',
  strategyInstanceUpdate: webServicePath + '/strategy/update',
  strategyInstanceDelete: webServicePath + '/strategy/delete',
  tradeApiServiceOpenOrder: webServicePath + '/ui-api/order',
  tradeApiServiceGeneralOrder: webServicePath + '/ui-api/generalOrder',
  tradeApiServiceGetAllPrice: webServicePath + '/ui-api/allPrice',
  tradeApiServiceGetAllActiveOrders: webServicePath + '/ui-api/allActiveOrders',
  tradeApiServiceCancelOrder: webServicePath + '/ui-api/cancel',
  tradeApiServiceGetAllPosition: webServicePath + '/ui-api/allPosition',
  tradeApiServiceGetAllSpot: webServicePath + '/ui-api/allSpot',
  tradeApiServiceGetOrderHistory: webServicePath + '/ui-api/allOrderHistory',
  tradeApiServiceGetOrderAccountInfo: webServicePath + '/ui-api/accountInfo',
  tradeApiServiceGetOrderAccountRisk: webServicePath + '/ui-api/accountRisk',
  tradeApiServiceGetOrderPositionMode: webServicePath + '/ui-api/positionMode',
  tradeApiServiceGetOkAssetBalance: webServicePath + '/ui-api/getOkAssetBalance',
  tradeApiServiceAssetTransfer: webServicePath + '/ui-api/assetTransfer',
  tradeApiServiceGetOkxTickers: webServicePath + '/ui-api/getOkxTickers',
  dataServiceAhr999: webServicePath + '/data-api/ahr999',
  dataServiceFunding: webServicePath + '/data-api/funding',
  dataServiceLiquidation: webServicePath + '/data-api/liquidation',
  dataServiceOptionDelivery: webServicePath + '/data-api/optionDelivery',
  dataServiceOptionStrike: webServicePath + '/data-api/optionStrike',
  dataServiceFutureProfit: webServicePath + '/data-api/futureProfit',
  dataServiceKLine: webServicePath + '/data-api/kline',
  dataServiceAllFundingFee: webServicePath + '/data-api/allFundingRecords',
  crmAddChannelRemark: webServicePath + '/v1/forthtech/customer/addChannelRemark',
  crmAddCustomerRemark: webServicePath + '/v1/forthtech/customer/addCustomerRemark',
  crmAddCustomerSettlementInfo: webServicePath + '/v1/forthtech/customer/addCustomerSettlementInfo',
  crmAddCustomerSettlement: webServicePath + '/v1/forthtech/customer/addCustomerSettlement',
  crmAddNewChannel: webServicePath + '/v1/forthtech/customer/addNewChannel',
  crmCheckCasualId: webServicePath + '/v1/forthtech/customer/checkCasualId',
  crmCreateCasualID: webServicePath + '/v1/forthtech/customer/createCasualId',
  crmCreateSteSettlementFile: webServicePath + '/v1/forthtech/customer/createSteSettlementFile',
  crmDeleteChannel: webServicePath + '/v1/forthtech/customer/deleteChannel',
  crmDeleteCustomer: webServicePath + '/v1/forthtech/customer/deleteCustomer',
  crmGetChannelFundsAmount: webServicePath + '/v1/forthtech/customer/getChannelFundsAmount',
  crmQueryChannel: webServicePath + '/v1/forthtech/customer/query/channel',
  crmQureyChannelRemark: webServicePath + '/v1/forthtech/customer/query/channelRemark',
  crmQueryCustomerRemark: webServicePath + '/v1/forthtech/customer/query/customerRemark',
  crmQueryCustomer: webServicePath + '/v1/forthtech/customer/query/customers',
  crmQueryCustomerSettleInfo: webServicePath + '/v1/forthtech/customer/query/customerSettleInfo',
  crmQueryCustomerSettle: webServicePath + '/v1/forthtech/customer/query/customerSettle',
  crmRegisterCustomer: webServicePath + '/v1/forthtech/customer/register',
  crmAddNewAccount: webServicePath + '/v1/forthtech/customer/addNewAccount',
  crmSetApiInfo: webServicePath + '/v1/forthtech/customer/setApiInfo',
  crmUpdateChannel: webServicePath + '/v1/forthtech/customer/updateChannel',
  crmUpdateCustomerInfo: webServicePath + '/v1/forthtech/customer/updateCustomerInfo',
  crmLikeQueryCustomers: webServicePath + '/v1/forthtech/customer/fuzzyquery/customers',
  crmLikeQueryChannels: webServicePath + '/v1/forthtech/customer/fuzzyQuery/channel',
  crmGetCustomerSizeByProgress: webServicePath + '/v1/forthtech/customer/getCustomerSizeByProgress',
  crmDeleteChannelRemark: webServicePath + '/v1/forthtech/customer/deleteChannelRemark',
  crmDeleteCustomerRemark: webServicePath + '/v1/forthtech/customer/deleteCustomerRemark',
  crmDeleteSettleInfo: webServicePath + '/v1/forthtech/customer/deleteSettleInfo',
  accountPlanView: webServicePath + '/accountPlan/view',
  accountPlanUpdate: webServicePath + '/accountPlan/update',
  generalExchangeServiceGetAssetMode: webServicePath + '/operation/general/getAssetMode',
  generalExchangeServiceChangeAssetMode: webServicePath + '/operation/general/updateAssetMode',
  generalExchangeServiceUpdateLeverage: webServicePath + '/operation/general/updateLeverage',
  walletCreateCexWallet: webServicePath + '/wallet/createCexWallet',
  walletCreateDexWallet: webServicePath + '/wallet/createDexWallet',
  walletDeleteWallet: webServicePath + '/wallet/deleteWallet',
  walletCreateTransfer: webServicePath + '/transfer/createTransfer',
  walletApproveTransfer: webServicePath + '/transfer/approve',
  walletRevokeApprovalTransfer: webServicePath + '/transfer/revokeApproval',
  walletListCexWallet: webServicePath + '/wallet/listCexWallet',
  walletListDexWallet: webServicePath + '/wallet/listDexWallet',
  walletGetCexTransferConstraint: webServicePath + '/wallet/getCexTransferConstraint',
  walletListCommonUsedCexWallet: webServicePath + '/wallet/listCommonUsedCexWallet',
  walletListCommonUsedDexWallet: webServicePath + '/wallet/listCommonUsedDexWallet',
  walletAddCommonUsedWallet: webServicePath + '/wallet/addCommonUsedWallet',
  walletDeleteCommonUsedWallet: webServicePath + '/wallet/deleteCommonUsedWallet',
  walletListPendingTransfers: webServicePath + '/transfer/listPendingTransfers',
  walletListFinishedTransfers: webServicePath + '/transfer/listFinishedTransfers',
  walletListMyFinishedTransfers: webServicePath + '/transfer/listMyFinishedTransfers',
  walletListICanApproveTransfers: webServicePath + '/transfer/listICanApproveTransfers',
  walletListCreatedByMeTransfers: webServicePath + '/transfer/listCreatedByMeTransfers',
  walletListAllDexChainTokenMappings: webServicePath + '/wallet/listAllDexChainTokenMappings',
  walletListDexWalletCreatedByRequester: webServicePath + '/wallet/listDexWalletCreatedByRequester',
  walletListCexWalletCreatedByRequester: webServicePath + '/wallet/listCexWalletCreatedByRequester',
  walletListDexWalletApprovedByRequester: webServicePath + '/wallet/listDexWalletApprovedByRequester',
  walletListCexWalletApprovedByRequester: webServicePath + '/wallet/listCexWalletApprovedByRequester',
  walletUpdateWalletApprovers: webServicePath + '/wallet/updateWalletApprovers',
  walletQueryCexBalanceOf: webServicePath + '/wallet/cexBalanceOf',
  walletQueryDexBalanceOf: webServicePath + '/wallet/dexBalanceOf',
  walletIsValidCexWallet: webServicePath + '/wallet/isValidCexWallet',
  walletIsValidDexWallet: webServicePath + '/wallet/isValidDexWallet',
  generalApiServiceKLineList: webServicePath + '/general-api/v1/getKLineList',
  generalApiServiceFirstAskBid: webServicePath + '/general-api/v1/getFirstAskBid',
  generalApiServiceAllPrice: webServicePath + '/general-api/v1/getAllPrice',
  generalApiServiceAllSymbolModel: webServicePath + '/general-api/v1/getAllSymbolModel',
  generalApiServiceAllActiveOrders: webServicePath + '/general-api/v1/getAllActiveOrders',
  generalApiServiceAssetsBalance: webServicePath + '/general-api/v1/getAssetsBalance',
  generalApiServiceCancelOrder: webServicePath + '/general-api/v1/cancelOrder',
  generalApiServiceUpdateLeverage: webServicePath + '/general-api/v1/updateLeverage',
  generalApiServiceAllPositions: webServicePath + '/general-api/v1/getAllPositions',
  generalApiServiceOrderHistory: webServicePath + '/general-api/v1/getOrderHistory',
  generalApiServiceMarketOrder: webServicePath + '/general-api/v1/placeMarketOrder',
  generalApiServiceLimitOrder: webServicePath + '/general-api/v1/placeLimitOrder',
  settlementCreate: webServicePath + '/settlement/create',
  strategyLibCreate: webServicePath + '/strategylib/create',
  strategyLibUpdate: webServicePath + '/strategylib/update',
  strategyLibQuery: webServicePath + '/strategylib/query',
  strategyLibCommentAdd: webServicePath + '/strategylib/comment/{strategyLibId}/add',
  crossPairHistoryOrder: webServicePath + '/pairsTrading/getOrderInfoList',
  crossPairResetHistoryOrder: webServicePath + '/pairsTrading/resetHistoryOrderBySid',
  crossPairSlopeChangeRate: webServicePath + '/pairsTrading/getSlopeChangeRate',
  crossPairBackTradeQueryResult: webServicePath + '/pairsTrading/queryBackTradeResult',
  queryBackTradePictureData: webServicePath + '/pairsTrading/queryBackTradePictureData',
  uniPlusWithdrawTokenToWallet: webServicePath + '/ui-uni-plus/withdrawTokenToWallet',
  uniPlusQueryBalance: webServicePath + '/ui-uni-plus/queryReadableBalance',
  uniPlusTransfer: webServicePath + '/ui-uni-plus/transfer',
  saveArticle:  webServicePath + '/article/create',
  loadArticle:  webServicePath + '/article/query',
  deleteArticle:  webServicePath + '/article/delete',
  getArticleList: webServicePath + '/article/list',
  uploadImage: webServicePath + '/article/uploadImage'

}

const TRACUP_SERVICE = {
  projectView: 'https://www.tracup.com/apiv1/project/view',
  issueList: 'https://www.tracup.com/apiv1/issue/listIssue',
  issueDetails: 'https://www.tracup.com/apiv1/issue/view',
  updateIssue: 'https://www.tracup.com/apiv1/issue/update',
  addNote: 'https://www.tracup.com/apiv1/issue/addNote',
  createIssue: 'https://www.tracup.com/apiv1/issue/create'
}

const CHAIN_UI_SERVICE = {
  getAddressSummary: webServicePath + '/chain-ui/getAddressSummary',
  getTokenList: webServicePath + '/chain-ui/getTokenList',
  getERC721List: webServicePath + '/chain-ui/getErc721List',
  getERC721Info: webServicePath + '/chain-ui/getErc721TokenInfo',
  talkGPT: webServicePath + '/chain-ui/talkGPT',
}

//敏感数据的写入接口，涉及直接交易的接口
let db_write_api = [
    WEB_SERVICE.updateUserRole,
    WEB_SERVICE.updateRolePermission,
    WEB_SERVICE.instanceCreate,
    WEB_SERVICE.instanceUpdateName,
    WEB_SERVICE.instanceReset,
    WEB_SERVICE.instanceDelete,
    WEB_SERVICE.strategyInstanceCreate,
    WEB_SERVICE.strategyInstanceUpdate,
    WEB_SERVICE.strategyInstanceReset,
    WEB_SERVICE.strategyInstanceDelete,
    WEB_SERVICE.tradeApiServiceOpenOrder,
    WEB_SERVICE.tradeApiServiceGeneralOrder,
    WEB_SERVICE.updateWebPermissionConfig,
    WEB_SERVICE.generalApiServiceCancelOrder,
    WEB_SERVICE.generalApiServiceMarketOrder,
    WEB_SERVICE.generalApiServiceLimitOrder,
    WEB_SERVICE.settlementCreate
]



export default {
  WEB_SERVICE,
  DATA_CENTER,
  CHAIN_UI_SERVICE,
  TRACUP_SERVICE,
  db_write_api
}
</script>
