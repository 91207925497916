<template>
  <div :size="size">
    <el-drawer
        size="100%"
        :style="'z-index:9999;width:' + width + ''"
        :modal="false"
        append-to-body
        :close-on-click-modal=false
        :close-on-press-escape=true
        :wrapperClosable=false
        :before-close="close"
        custom-class="black-card"
        :visible.sync="drawer"
        direction="ltr"
        @keyup.esc.native="drawer = false">
      <div style="text-align: center;margin-top: -3px;">
        <h4 class="dark-mode-font" style="font-size: 19px;color: white"><b>Account Information</b>
          <el-popover
              @show="renderAccountPlan"
              placement="bottom-end"
              :width="popWidth"
              trigger="click">
            <account-plan :ref="'position-account-plan-' + ins.instanceId"></account-plan>
            <el-button slot="reference" v-auth:accountPlan
                       style="background-color: rgba(0, 0, 0, 0);outline: none;border: 0;height: 18px;line-height: 18px;margin-left: 15px;margin-top: -20px;padding: 0">
              <i class="el-icon-edit-outline" style="font-size: 18px"></i></el-button>
          </el-popover>
        </h4>
      </div>
      <div style="width: 100%;height: 92.5%;padding: 11px 25px 50px 25px;">
        <mu-tabs :value.sync="active" color="#22272e" indicator-color="yellow" full-width center @change="changeTabs">
          <mu-tab v-for="obj in ins.tradeAccountList" :key="obj.id" :value="obj.id" style="border-bottom: 1px solid gray;">{{ obj.platform }}
          </mu-tab>
        </mu-tabs>
        <ftx-account-info v-if="platform === 'FTX'" ref="FTX-ACCOUNT"></ftx-account-info>
        <deribit-account-info v-if="platform === 'DERIBIT'" ref="DERIBIT-ACCOUNT"></deribit-account-info>
        <okx-account-info v-if="platform === 'OKX'" ref="OKX-ACCOUNT"></okx-account-info>
        <binance-account-info v-if="platform === 'BINANCE'" ref="BINANCE-ACCOUNT"></binance-account-info>
        <bybit-account-info v-if="platform === 'BYBIT'" ref="BYBIT-ACCOUNT"></bybit-account-info>
        <mexc-account-info v-if="platform === 'MEXC'" ref="MEXC-ACCOUNT"></mexc-account-info>
        <dydx-account-info v-if="platform === 'DYDX'" ref="DYDX-ACCOUNT"></dydx-account-info>
        <evm-based-wallet-account-info v-if="platform === 'EVM_BASED_WALLET'" ref="EVM_BASED_WALLET-ACCOUNT"></evm-based-wallet-account-info>
      </div>
      <el-tag v-if="!isConfigCustomer()" class="fix_left_down" type="danger" style="margin-left: 20px;margin-bottom: -0.3%" v-auth:bindCMS>客户未关联</el-tag>
      <div v-if="isDisplayCustomerSettlement()" class="dark-mode-font fix_left_down" v-auth:bindCMS>
        <el-tag class="" type="success" style="margin-left: 20px;margin-bottom: -8px">客户名:{{ ins.customerInfo.name }}</el-tag>
        <el-tag :type="settlementColorLevel" class="ml-2">结算时间:{{ getDisplayDatetime() }}</el-tag>
        <el-tag :type="settlementColorLevel" class="ml-2">{{ getDiffBeforeString() }}</el-tag>
      </div>

    </el-drawer>
  </div>
</template>

<script>

import FtxAccountInfo from "@/pages/controlPanel/accountMgnt/account/FtxAccountInfo";
import DeribitAccountInfo from "@/pages/controlPanel/accountMgnt/account/DeribitAccountInfo";
import OkxAccountInfo from "@/pages/controlPanel/accountMgnt/account/OkxAccountInfo";
import BinanceAccountInfo from "@/pages/controlPanel/accountMgnt/account/BinanceAccountInfo";
import AccountPlan from "@/pages/controlPanel/accountMgnt/AccountPlan";
import BybitAccountInfo from "@/pages/controlPanel/accountMgnt/account/BybitAccountInfo";
import ConfirmProvider from "@/components/ConfirmProvider";
import CacheService from "@/cache/CacheService";
import UserPermissionService from "@/api/user/UserPermissionService";
import MexcAccountInfo from "@/pages/controlPanel/accountMgnt/account/MexcAccountInfo";
import DydxAccountInfo from "@/pages/controlPanel/accountMgnt/account/DydxAccountInfo";
import EvmBasedWalletAccountInfo from "@/pages/controlPanel/accountMgnt/account/EvmBasedWalletAccountInfo";

export default {
  name: "PositionPanel",
  components: {
    EvmBasedWalletAccountInfo,
    DydxAccountInfo,
    MexcAccountInfo,
    BybitAccountInfo, AccountPlan, BinanceAccountInfo, OkxAccountInfo, DeribitAccountInfo, FtxAccountInfo},
  props: {
    size: String
  },
  data: function () {
    return {
      settlementColorLevel: "success",
      active: 0,
      platform: '',
      drawer: false,
      ins: {},
      tradeIns: {},
      processing: false,
      width: '100%',
      popWidth: '100%'
    }
  },
  methods: {
    renderAccountPlan() {
      this.$refs['position-account-plan-' + this.ins.instanceId].render(this.ins);
    },
    renderAccount: function () {
      this.$nextTick(function () {
        this.$refs[this.platform + '-ACCOUNT'].render(this.ins, this.tradeIns);
      });
    },
    render: function (instance, width) {
      this.width = width;
      this.ins = instance;
      this.popWidth = document.documentElement.clientWidth > 1000 ? (document.documentElement.clientWidth * 0.4) : '100%';
      this.active = instance.tradeAccountList[0].id;
      this.tradeIns = instance.tradeAccountList[0];
      this.platform = instance.tradeAccountList[0].platform;
      this.$forceUpdate();
      this.renderAccount();
      this.drawer = true;
      if (UserPermissionService.isAllowed(UserPermissionService.all_permission.bindCMS)) {
        let settleTime = this.getDiffBefore();
        if (this.isConfigCustomer() && settleTime > 0) {
          if (settleTime <= 7) {
            this.openCrmWarning(7);
          } else if (settleTime <= 14) {
            this.openCrmWarning(14);
          } else if (settleTime <= 30) {
            this.openCrmWarning(30);
          }
        }
        if (this.isConfigCustomer() && this.getDiffBefore() <= 30) {
          this.settlementColorLevel = 'danger'
        }
      }
    },
    openCrmWarning: function (type) {
      let cacheKey = "ShowSettlement-" + this.ins.instanceId + "-" + this.ins.customerInfo.name + "-" + type;
      if (CacheService.get(cacheKey) !== 'Showed') {
        ConfirmProvider.openConfirm(this, '操作确认', '距离该账号结算还有' + this.getDiffBefore() + '天，请谨慎开仓', function () {
          CacheService.set(cacheKey, "Showed", 1000 * 60 * 60 * 24 * type);
        });
      }
    },
    close: function () {
      window.$crisp.push(["do", "chat:show"]);
      this.drawer = false;
      this.$nextTick(function () {
        this.$refs[this.platform + '-ACCOUNT'].uninstall();
      });
    },
    getDisplayDatetime: function () {
      let d = new Date(this.ins.customerInfo.settlementTime * 1000)
      return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
    },
    isConfigCustomer: function () {
      return this.ins.customerInfo && this.ins.customerInfo.name;
    },
    isDisplayCustomerSettlement: function () {
      return this.isConfigCustomer() && this.getDiffBefore() <= 30;
    },
    getDiffBeforeString: function () {
      var diffDay = this.getDiffBefore();
      if (diffDay > 0) {
        return "距离结算还有[" + diffDay + "]天";
      } else {
        return "超过结算日已经[" + (-diffDay) + "]天，请联系客户管理员"
      }
    },
    getDiffBefore: function () {
      if (this.isConfigCustomer()) {
        var diffGap = (this.ins.customerInfo.settlementTime * 1000) - new Date().getTime();
        return Number((diffGap / (1000 * 60 * 60 * 24)).toFixed(1));
      } else {
        return -1;
      }

    },
    changeTabs: function () {
      for (let index in this.ins.tradeAccountList) {
        let account = this.ins.tradeAccountList[index];
        if (account.id === this.active) {
          this.tradeIns = account;
          this.platform = account.platform;
          break;
        }
      }
      this.renderAccount();
    }
  }
}
</script>

<style scoped>

.mu-tab {
  min-width: 60px;
}

.fix_left_down {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index:9999;
}

</style>