import Vue from 'vue'
import Router from 'vue-router'
import ControlPanel from '../pages/controlPanel/ControlPanel'
import Login from "@/components/Login";
import AccountMgnt from "@/pages/controlPanel/accountMgnt/AccountMgnt";
import UserService from "@/api/user/UserService";
import RouterPath from "./RouterPath"
import ControlPanelHome from "@/pages/controlPanel/home/ControlPanelHome";
import RiskMgnt from "@/pages/controlPanel/risk/RiskMgnt";
import TestMgnt from "@/pages/controlPanel/test/TestMgnt";
import Web3Mgnt from "@/pages/controlPanel/web3/Web3Mgnt";
import TradeMgnt from "@/pages/controlPanel/tradeMgnt/TradeMgnt";
import UserPermissionManagement from "@/pages/controlPanel/adminPanel/UserPermissionManagement";
import MobileUserManagement from "@/pages/controlPanel/mobile/MobileUserManagement";
import MobileNews from "@/pages/controlPanel/mobile/MobileNews";
import FtChatGPT from "@/pages/controlPanel/mobile/MobileChatGPT";
import AnalysisMgnt from "@/pages/controlPanel/analysis/AnalysisMgnt";
import Lock from "@/components/Lock";
import TraCupPanel from "@/pages/controlPanel/adminPanel/TraCupPanel";
import ChannelMgnt from "@/pages/controlPanel/crm/ChannelMgnt";
import CustomerKey from "@/pages/controlPanel/customer/CustomerKey";
import Wallet from "@/pages/controlPanel/wallet/Wallet";
import StrategyMgnt from "@/pages/controlPanel/strategyMgnt/StrategyMgnt";
import ArticlePublish from "@/pages/controlPanel/cms/ArticlePublish.vue";

Vue.use(Router);

const route = new Router({
    base: RouterPath.basic,
    mode: 'history',
    routes: [{
        path: RouterPath.router.login,
        component: Login
    }, {
        path: RouterPath.router.lock,
        component: Lock
    }, {
        path: RouterPath.router.customerKey,
        component: CustomerKey
    }, {
        path: RouterPath.router.controlPanel,
        component: ControlPanel,
        redirect: RouterPath.router.controlPanelChild.controlPanelHome,
        children: [{
            path: RouterPath.router.controlPanelChild.controlPanelHome,
            component: ControlPanelHome,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.accountMgnt,
            component: AccountMgnt,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.analysisMgnt,
            component: AnalysisMgnt,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.strategyMgnt,
            component: StrategyMgnt,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.tradeMgnt,
            component: TradeMgnt,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.riskMgnt,
            component: RiskMgnt,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.testMgnt,
            component: TestMgnt,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.web3Mgnt,
            component: Web3Mgnt,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.userPermissionManagement,
            component: UserPermissionManagement,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.mobileUserConfigMgnt,
            component: MobileUserManagement,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.mobileNews,
            component: MobileNews,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.ftChatGPT,
            component: FtChatGPT,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.traCupPanel,
            component: TraCupPanel,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.channelMgnt,
            component: ChannelMgnt,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.wallet,
            component: Wallet,
            meta: {requiresAuth: true}
        }, {
            path: RouterPath.router.controlPanelChild.cms,
            component: ArticlePublish,
            meta: {requiresAuth: true}
        }]
    }, {
        path: '/*',
        redirect: RouterPath.router.login
    }]
});

//登录后无法访问的页面

//校验权限与跳转
route.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (UserService.isLogin()) {
            UserService.isValidLoginUser(() => {
                UserService.hasAccessPermission(to.path, () => {
                    if (UserService.isLock()) {
                        next({path: RouterPath.router.lock})
                    } else {
                        next()
                    }
                }, () => {
                    next(false)
                })
            }, () => {
                // session的假数据清理
                UserService.clear()
                next({path: RouterPath.router.login})
            })
        } else {
            next({path: RouterPath.router.login})
        }
    } else {
        next()
    }
});

export default route;
