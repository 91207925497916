<script>

import RouterPath from "@/router/RouterPath";
import UserPermissionService from "@/api/user/UserPermissionService";
// import UserService from "@/api/user/UserService";

const mobileNavBars = [
  {
    index: 0,
    path: RouterPath.router.controlPanelChild.controlPanelHome,
    label: 'mobileNav.controlPanel',
    icon: 'el-icon-s-home'
  },{
    index: 1,
    path: RouterPath.router.controlPanelChild.accountMgnt,
    label: 'mobileNav.accountMgnt',
    icon: 'el-icon-s-grid'
  }, {
    index: 2,
    path: RouterPath.router.controlPanelChild.analysisMgnt,
    label: 'navBars.analysisMgnt',
    icon: 'el-icon-s-platform'
  },{
    index: 3,
    path: RouterPath.router.controlPanelChild.tradeMgnt,
    label: 'mobileNav.tradeMgnt',
    icon: 'el-icon-s-marketing'
  },{
    index: 4,
    path: RouterPath.router.controlPanelChild.mobileNews,
    label: 'mobileNav.news',
    icon: 'el-icon-s-promotion'
  },{
    index: 5,
    path: RouterPath.router.controlPanelChild.ftChatGPT,
    label: 'mobileNav.chatGPT',
    icon: 'el-icon-s-opportunity'
  },{
    index: 6,
    path: RouterPath.router.controlPanelChild.mobileUserConfigMgnt,
    label: 'mobileNav.userConfig',
    icon: 'el-icon-s-tools'
  }
];

const navBars = [
  {
    index: "1",
    name: 'ControlPanel',
    displayNameKey: 'navBars.controlPanel',
    path: RouterPath.router.controlPanelChild.controlPanelHome,
    icon: 'el-icon-s-home',
    active: true
  }, {
    index: "2",
    name: 'AccountMgnt',
    displayNameKey: 'navBars.accountMgnt',
    path: RouterPath.router.controlPanelChild.accountMgnt,
    icon: 'el-icon-s-grid',
    active: true
  }, {
    index: "3",
    name: 'AnalysisMgnt',
    displayNameKey: 'navBars.analysisMgnt',
    path: RouterPath.router.controlPanelChild.analysisMgnt,
    icon: 'el-icon-s-platform',
    active: true
  }, {
    index: "4",
    name: 'StrategyMgnt',
    displayNameKey: 'navBars.strategyMgnt',
    path: RouterPath.router.controlPanelChild.strategyMgnt,
    icon: 'el-icon-s-cooperation',
    active: true
  }, {
    index: "5",
    name: 'TradeMgnt',
    displayNameKey: 'navBars.tradeMgnt',
    path: RouterPath.router.controlPanelChild.tradeMgnt,
    icon: 'el-icon-s-marketing',
    active: true
  }, {
    index: "6",
    name: 'RiskMgnt',
    displayNameKey: 'navBars.riskMgnt',
    path: RouterPath.router.controlPanelChild.riskMgnt,
    icon: 'el-icon-s-marketing',
    active: false
  }, {
    index: "7",
    name: 'TestMgnt',
    displayNameKey: 'navBars.testMgnt',
    path: RouterPath.router.controlPanelChild.testMgnt,
    icon: 'el-icon-s-data',
    active: false
  }, {
    index: "8",
    name: 'Web3Mgnt',
    displayNameKey: 'navBars.web3Mgnt',
    path: RouterPath.router.controlPanelChild.web3Mgnt,
    icon: 'el-icon-s-help',
    active: true
  }, {
    index: "9",
    name: 'UserPermissionManagement',
    displayNameKey: 'navBars.userPermissionManagement',
    path: RouterPath.router.controlPanelChild.userPermissionManagement,
    icon: 'el-icon-user-solid',
    active: true
  }, {
    index: "10",
    name: 'TraCupPanel',
    displayNameKey: 'navBars.traCupPanel',
    path: RouterPath.router.controlPanelChild.traCupPanel,
    icon: 'el-icon-s-claim',
    active: true
  }, {
    index: "11",
    name: 'crmMgnt',
    displayNameKey: 'navBars.channelMgnt',
    path: RouterPath.router.controlPanelChild.channelMgnt,
    icon: 'el-icon-s-custom',
    active: true
  }, {
    index: "12",
    name: 'wallet',
    displayNameKey: 'navBars.wallet',
    path: RouterPath.router.controlPanelChild.wallet,
    icon: 'el-icon-s-finance',
    active: true
  },
  {
    index: "13",
    name: 'cmsMgmt',
    displayNameKey: 'navBars.cms',
    path: RouterPath.router.controlPanelChild.cms,
    icon: 'el-icon-s-management',
    active: true
  }
];

// TODO 顺序怎么保持一致?
function filterNavBars() {
  let afterFilter = [];
  for (const nv of navBars) {
    if (nv.active) {
      if (UserPermissionService.isAllowed(nv.name)) {
        afterFilter.push(nv);
      }
    }
  }
  return afterFilter;
}

export default {
  mobileNavBars,
  navBars,
  filterNavBars
}
</script>
