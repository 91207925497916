var render, staticRenderFns
import script from "./UserPermissionService.vue?vue&type=script&lang=js"
export * from "./UserPermissionService.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2158972",
  null
  
)

export default component.exports