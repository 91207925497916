<template>
  <div :size="size">
    <el-drawer
        :size="size"
        :modal="false"
        append-to-body
        :close-on-click-modal=false
        :close-on-press-escape=true
        :wrapperClosable=false
        custom-class="black-card"
        :visible.sync="drawer"
        :before-close="closeAll"
        direction="rtl"
        @keyup.esc.native="closeAll">
      <div style="text-align: center;margin-top: -3px;">
        <h4 class="dark-mode-font" style="font-size: 20px;color: white"><b>{{ instance.name }}</b></h4>
      </div>
      <div style="width: 100%;height: 92.5%;padding: 10px 25px 25px 25px;">
        <mu-tabs :value.sync="active" color="#22272e" indicator-color="yellow" full-width center @change="changeTabs">
          <mu-tab style="border-bottom: 1px solid gray" v-for="obj in renderStrategies" :key="obj.index" :value="obj.index">{{ obj.name }}
          </mu-tab>
        </mu-tabs>
        <el-carousel id="strategy-carousel" arrow="always" :autoplay="false" style="height: calc(96% - 168px)" height="100%" @change="changeStrategy" v-if="renderStrategies.length > 0">
          <el-carousel-item v-for="item in renderStrategies[active].subStrategies" :key="item.strategy.strategyName" style="height: 100%;">
            <el-card id="out-container" shadow="none">
              <div style="text-align: center;">
                <h4 class="dark-mode-font" style="font-size: 18px;color: white"><b>{{ item.strategy.strategyName.replaceAll('_', ' ') }}</b>
                  <el-tooltip class="item" effect="dark" :content="strategyHelp[item.strategy.strategyName]" placement="top">
                    <i class="el-icon-info" v-if="strategyHelp[item.strategy.strategyName]" style="margin-left: 10px;font-size: 14px"></i>
                  </el-tooltip>
                </h4>
              </div>
              <el-card id="strategy-ins-container" class="strategy-config-container" style="background-color: rgba(0, 0, 0, 0);border: 0;overflow-y: auto;overflow-x: hidden;margin-top: -5px" shadow="none">
                <open-task-general-hedge v-if="item.strategy.strategyName === 'General_Hedge_Open'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></open-task-general-hedge>
                <open-task-ftx-hedge v-if="item.strategy.strategyName === 'Ftx_Hedge_Open'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></open-task-ftx-hedge>
                <close-task-ftx-hedge v-if="item.strategy.strategyName === 'Ftx_Hedge_Close'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></close-task-ftx-hedge>
                <open-task-binance-hedge v-if="item.strategy.strategyName === 'Binance_Hedge_Open'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></open-task-binance-hedge>
                <close-task-binance-hedge v-if="item.strategy.strategyName === 'Binance_Hedge_Close'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></close-task-binance-hedge>
                <open-task-deribit-hedge v-if="item.strategy.strategyName === 'Deribit_Hedge_Open'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></open-task-deribit-hedge>
                <close-task-deribit-hedge v-if="item.strategy.strategyName === 'Deribit_Hedge_Close'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></close-task-deribit-hedge>
                <generic-deribit-delta-hedge v-if="item.strategy.strategyName === 'Deribit_Delta_Hedge_Open'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></generic-deribit-delta-hedge>
                <open-task-okx-usdt-hedge v-if="item.strategy.strategyName === 'Okx_Usdt_Hedge_Open'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></open-task-okx-usdt-hedge>
                <close-task-okx-usdt-hedge v-if="item.strategy.strategyName === 'Okx_Usdt_Hedge_Close'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></close-task-okx-usdt-hedge>
                <dca-ftx v-if="item.strategy.strategyName === 'DCA'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></dca-ftx>
                <BTCDOM v-if="item.strategy.strategyName === 'BTCDOM_HEDGE'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></BTCDOM>
                <BLUEBIRD v-if="item.strategy.strategyName === 'BLUEBIRD_HEDGE'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></BLUEBIRD>
                <gmx-hedge v-if="item.strategy.strategyName === 'GMX_HEDGE'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></gmx-hedge>
                <uni-hedge v-if="item.strategy.strategyName === 'UNI_HEDGE'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></uni-hedge>
                <funding-fee-strategy-config-v2 v-if="item.strategy.strategyName === 'FUNDING_FEE'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></funding-fee-strategy-config-v2>
                <generic-cross-pair v-if="item.strategy.strategyName === 'CROSS_PAIR'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></generic-cross-pair>
                <generic-cross-pair-back-trade v-if="item.strategy.strategyName === 'CROSS_PAIR_BACK_TRADE'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></generic-cross-pair-back-trade>
                <all-spot-market v-if="item.strategy.strategyName === 'ALL_SPOT_INDEX'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></all-spot-market>
                <double-coin-win v-if="item.strategy.strategyName === 'Dual_Investment'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></double-coin-win>
                <double-coin-win-sell v-if="item.strategy.strategyName === 'Dual_Investment_Sell'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></double-coin-win-sell>
                <double-coin-win-okx v-if="item.strategy.strategyName === 'OKX_Dual_Investment'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></double-coin-win-okx>
                <spot-volatility v-if="item.strategy.strategyName === 'SPOT_VOLATILITY'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></spot-volatility>
                <simple-spot-grid v-if="item.strategy.strategyName === 'SIMPLE_SPOT_GRID'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></simple-spot-grid>
                <usd-contract-volatility v-if="item.strategy.strategyName === 'USD_CONTRACT_VOLATILITY'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></usd-contract-volatility>
                <coin-contract-volatility v-if="item.strategy.strategyName === 'COIN_CONTRACT_VOLATILITY'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></coin-contract-volatility>
                <uni-plus-boll v-if="item.strategy.strategyName === 'UNI_PLUS_BOLL'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></uni-plus-boll>
                <external-strategy v-if="item.strategy.strategyName === 'External Strategies'" :ref="active + '-' + item.strategy.strategyName" :dto="item.strategy"></external-strategy>
              </el-card>
            </el-card>
          </el-carousel-item>
        </el-carousel>
        <trade-vol-chart v-if="drawer" id="trade_vol_instance" ref="trade_vol_instance" :color="'#ffeb3b'" width="2"
                         style="height: 168px;margin-top: 5px"></trade-vol-chart>
      </div>
      <el-row class="strategy-loading" v-if="loadingModel">
        <div class="trialpha-loading" style="margin-left: 46%">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </el-row>
      <el-card class="black-card dark-mode-btn" v-if="!loadingModel" style="position: absolute;width: 45px;height: 45px;margin-top: -50px;border-radius: 50px;box-shadow: 0 0 10px #c1c1c1;margin-left: 86%;z-index: 99999;cursor: pointer;outline: none;border: 0"
               body-style="padding: 1px;" shadow="always" @click.native="render">
        <i class="el-icon-refresh" style="font-size: 42px;color: #FFFFFF;transform: rotate(100deg)"/>
      </el-card>
    </el-drawer>
  </div>
</template>

<script>

import OpenTaskFtxHedge from "@/pages/controlPanel/accountMgnt/strategies/openTask/ftx/HedgeOpenFtx";
import CloseTaskFtxHedge from "@/pages/controlPanel/accountMgnt/strategies/closeTask/ftx/HedgeCloseFtx";
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import UserPermissionService from "@/api/user/UserPermissionService";
import OpenTaskDeribitHedge from "@/pages/controlPanel/accountMgnt/strategies/openTask/deribit/HedgeOpenDeribit";
import CloseTaskDeribitHedge from "@/pages/controlPanel/accountMgnt/strategies/closeTask/deribit/HedgeCloseDeribit";
import OpenTaskOkxUsdtHedge from "@/pages/controlPanel/accountMgnt/strategies/openTask/okx/HedgeOpenOkx";
import CloseTaskOkxUsdtHedge from "@/pages/controlPanel/accountMgnt/strategies/closeTask/okx/HedgeCloseOkx";
import DcaFtx from "@/pages/controlPanel/accountMgnt/strategies/dca.ftx/DcaFtx";
import BTCDOM from "@/pages/controlPanel/accountMgnt/strategies/index/binance/BTCDOM";
import TradeVolService from "@/api/trade/TradeVolService";
import TradeVolChart from "@/pages/controlPanel/home/TradeVolChart";
import OpenTaskBinanceHedge from "@/pages/controlPanel/accountMgnt/strategies/openTask/binance/HedgeOpenBinanceUsd";
import CloseTaskBinanceHedge from "@/pages/controlPanel/accountMgnt/strategies/closeTask/binance/HedgeCloseBinance";
import GenericCrossPair from "@/pages/controlPanel/accountMgnt/strategies/crossPair/CrossPairGeneric";
import FundingFeeStrategyConfigV2
  from "@/pages/controlPanel/accountMgnt/strategies/fundingFee/FundingFeeStrategyConfigV2";
import OpenTaskGeneralHedge from "@/pages/controlPanel/accountMgnt/strategies/openTask/GeneralHedgeTask";
import BLUEBIRD from "@/pages/controlPanel/accountMgnt/strategies/index/binance/BlueBird";
import AllSpotMarket from "@/pages/controlPanel/accountMgnt/strategies/index/AllSpotMarketTask";
import GmxHedge from "@/pages/controlPanel/accountMgnt/strategies/index/gmx/GmxHedge";
import UniHedge from "@/pages/controlPanel/accountMgnt/strategies/index/uni/UniHedge";
import DoubleCoinWin from "@/pages/controlPanel/accountMgnt/strategies/openTask/deribit/DoubleCoinWin";
import DoubleCoinWinOkx from "@/pages/controlPanel/accountMgnt/strategies/openTask/okx/DoubleCoinWinOkx";
import DoubleCoinWinSell from "@/pages/controlPanel/accountMgnt/strategies/openTask/deribit/DoubleCoinWinSell";
import SimpleSpotGrid from "@/pages/controlPanel/accountMgnt/strategies/openTask/volatility/SimpleSpotGrid";
import SpotVolatility from "@/pages/controlPanel/accountMgnt/strategies/openTask/volatility/SpotVolatility";
import UsdContractVolatility
  from "@/pages/controlPanel/accountMgnt/strategies/openTask/volatility/UsdContractVolatility";
import CoinContractVolatility
  from "@/pages/controlPanel/accountMgnt/strategies/openTask/volatility/CoinContractVolatility";
import GenericCrossPairBackTrade from "@/pages/controlPanel/accountMgnt/strategies/crossPair/CrossPairBackTradeGeneric";
import GenericDeribitDeltaHedge from "@/pages/controlPanel/accountMgnt/strategies/openTask/deribit/DeribitDeltaHedgeGeneric";
import UniPlusBoll from "@/pages/controlPanel/accountMgnt/strategies/defi/UniPlusBoll.vue";
import ExternalStrategy from "@/pages/controlPanel/accountMgnt/strategies/openTask/external/ExternalStrategy.vue";

export default {
  name: "AccountConfig",
  components: {
    ExternalStrategy,
    UniPlusBoll,
    GenericDeribitDeltaHedge,
    GenericCrossPairBackTrade,
    CoinContractVolatility,
    UsdContractVolatility,
    SpotVolatility,
    SimpleSpotGrid,
    DoubleCoinWinSell,
    DoubleCoinWinOkx,
    DoubleCoinWin,
    UniHedge,
    GmxHedge,
    AllSpotMarket,
    BLUEBIRD,
    OpenTaskGeneralHedge,
    FundingFeeStrategyConfigV2,
    GenericCrossPair,
    CloseTaskBinanceHedge,
    OpenTaskBinanceHedge,
    TradeVolChart,
    BTCDOM,
    DcaFtx,
    CloseTaskOkxUsdtHedge,
    OpenTaskOkxUsdtHedge,
    CloseTaskDeribitHedge,
    OpenTaskDeribitHedge,
    CloseTaskFtxHedge,
    OpenTaskFtxHedge
  },
  props: {
    size: String
  },
  data: function () {
    return {
      active: 0,
      carouselIndex: 0,
      instance: {},
      adding: false,
      drawer: false,
      loadingModel: true,
      renderStrategies: [],
      strategyHelp: {
        General_Hedge_Open: '各交易所通用的对冲任务，可跨所对冲',
        Ftx_Hedge_Open: '开仓任务，差价以 （做空标的 - 做多标的） 来计算',
        Ftx_Hedge_Close: '平仓任务，差价以 （做多标的 - 做空标的） 来计算',
        Deribit_Hedge_Open: '开仓任务，差价以 （做空标的 - 做多标的） 来计算',
        Deribit_Hedge_Close: '平仓任务，差价以 （做多标的 - 做空标的） 来计算',
        Okx_Usdt_Hedge_Open: '开仓任务，仅限USDT合约或现货，差价以 （做空标的 - 做多标的） 来计算',
        Okx_Usdt_Hedge_Close: '平仓任务，仅限USDT合约或现货，差价以 （做多标的 - 做空标的） 来计算',
        Ftx_DCA: '基础定投任务',
        BTCDOM_HEDGE: '指数BTCDOMUSDT的对冲任务',
        BLUEBIRD_HEDGE: '指数BLUEBIRDUSDT的对冲任务'
      }
    }
  },
  methods: {
    render: function () {
      this.$nextTick(function () {
        this.$refs[this.active + '-' + this.renderStrategies[this.active].subStrategies[this.carouselIndex].strategy.strategyName][0].render(this.instance);
        this.$emit("refreshPosition",null)
      });
    },
    openDrawer: function (instance) {
      let that = this;
      UserPermissionService.isAllowedAndThen('configAccount', function () {
        that.drawer = true;
        that.instance = instance;
        let insExchange = [];
        that.renderStrategies = [];
        that.loadingModel = true;
        that.active = 0;
        instance.tradeAccountList.forEach((account) => {
          insExchange.push(account.platform);
        });
        let sIndex = 0;
        that.queryTradeVol();
        StrategyInstanceService.queryModel(that.instance.instanceId, function (model) {
          that.renderStrategies = model.filter((strategy) => {
            if (UserPermissionService.isAllowed('S_' + strategy.name)) {
              let subArray = strategy.subStrategies.filter((sub) => {
                if (sub.exchangeRequired.filter((e) => {
                  if (insExchange.indexOf(e) > -1 || e === 'ALL') {
                    return e;
                  }
                }).length > 0) {
                  return sub;
                }
              })
              if (subArray.length > 0) {
                strategy.index = sIndex++;
                strategy.subStrategies = subArray;
                return strategy;
              }
            }
          });
          if (that.renderStrategies.length > 0) {
            that.active = that.renderStrategies[0].index
          }
          that.loadingModel = false;
          that.changeStrategy(0);
        });
      });
    },
    changeStrategy(v) {
      this.carouselIndex = v;
      this.render();
    },
    changeTabs() {
      this.changeStrategy(0);
    },
    closeAll() {
      this.$emit('closePositionPanel',true);
      this.drawer = false;
      window.$crisp.push(["do", "chat:show"])
    },
    getExchangeDescription(obj) {
      let exchanges= [];
      obj.subStrategies.forEach((e) => {
        e.exchangeRequired.forEach((eInner) => {
          if (exchanges.indexOf(eInner) === -1) {
            exchanges.push(eInner);
          }
        });
      });
      return exchanges.toString();
    },
    queryTradeVol: function () {
      let that = this;
      that.$nextTick(function () {
        that.$refs['trade_vol_instance'].render([{"totalTradeCount":0,"totalVal":0.0,"dateString":"0000-00-00"}]);
      });
      TradeVolService.query(30, [that.instance.instanceId], function (data) {
        that.$nextTick(function () {
          that.$refs['trade_vol_instance'].render(data.lastNDayVolDateString);
        });
      });
    }
  }
}
</script>

<style>

.mu-tab {
  min-width: 60px;
}

.createAccount .el-input__inner {
  color: white;
  height: 36px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 3px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(119, 109, 109, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(119, 109, 109, 0.4);
}

#out-container {
  width: 100%;
  height: 95%;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
}

#out-container .el-card__body {
  width: 100%;
  height: 100%;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
}

#strategy-carousel .el-carousel__container .el-carousel__arrow.el-carousel__arrow--left {
  margin-left: -25px;
  border: none;
}

#strategy-carousel .el-carousel__container .el-carousel__arrow.el-carousel__arrow--right {
  margin-right: -25px;
  border: none;
}

#strategy-carousel .el-carousel__container .el-carousel__arrow {
  border: none;
  outline: none;
}

.strategy-config-container {
  width: 101%;
  margin-left: -0.5%;
  height: 98%;
  overflow-x: hidden;
  margin-top: -5px;
}

@media only screen and  (max-width: 568px) {
  .strategy-config-container {
    width: 116%;
    margin-left: -8%;
    height: 98%;
    overflow-x: hidden;
    margin-top: -5px;
  }
}

.strategy-loading {
  width: 100%;
  height: 50px;
  position: absolute;
  margin-top: -65%;
  margin-left: 35px;
  z-index: 999999
}

@media only screen and  (max-width: 568px) {
  .strategy-loading {
    width: 100%;
    height: 50px;
    position: absolute;
    margin-top: -100%;
    margin-left: 0;
    z-index: 999999
  }
}
::-webkit-scrollbar {
  width: 2px;
}

@media only screen and  (max-width: 1000px)  {
  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  /* 设置滚动条的样式 */
  body::-webkit-scrollbar {
    width: 0;
    display: none;
  }

}

.el-scrollbar__wrap {
  overflow-x: hidden;
}

</style>