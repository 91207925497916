import { render, staticRenderFns } from "./RiskMgnt.vue?vue&type=template&id=4f836cf0&scoped=true"
import script from "./RiskMgnt.vue?vue&type=script&lang=js"
export * from "./RiskMgnt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f836cf0",
  null
  
)

export default component.exports