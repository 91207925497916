<template>
  <div :dto="dto">
    <el-skeleton animated :loading="rendering" :throttle="268">
      <template slot="template">
        <trialpha-sk style="margin-top: 0;width: 60%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 40%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 100%;float: left"></trialpha-sk>
      </template>
      <template>
        <el-row>
          <el-collapse class="trialpha-collapse" v-model="activeNames" style="border: 0">
            <el-collapse-item class="trialpha-collapse" style="border: 0"
                              :name="ins.sid"
                              v-for="ins in instanceList"
                              :key="ins.sid">
              <template slot="title">
                <el-row style="width: 100%;display: flex">
                  <el-card class="black-card" body-style="padding: 0"
                           style="line-height: 46px;margin-left: -10px;margin-top: 0;border-left: 0;border-top: 0;border-bottom: 0;border-radius: 0;height: 46px;width: 68px;background-color: #444c56;text-align: center">
                      <span class="dark-mode-font" style=";font-size: 12px;width: 50px">
                        External
                      </span>
                  </el-card>
                </el-row>
              </template>
              <el-row style="height: 568px;overflow-y: auto">
                <el-row style="width: 98%;margin-top: 8px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">实例状态 (ID: {{ ins.sid }})</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-select class="select-symbol" style="width: 100%" v-model="ins.isActive" placeholder="请选择">
                      <el-option
                          v-for="item in [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">下单方式</span>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">External URL</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-select class="select-symbol" :value="true" style="width: 100%" placeholder="请选择">
                      <el-option
                          v-for="item in [{value: true, label: 'MARKET ORDER'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12" :offset="1" style="display: flex">
                    <el-tooltip class="item" effect="dark" content="外部策略的调用地址" placement="top-start" :open-delay="666">
                      <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.externalSignalUrl"/>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">总仓位最大美元价值</span>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">单个Symbol单次最大成交美元</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-tooltip class="item" effect="dark" content="仓位总美元价值" placement="top-start" :open-delay="666">
                      <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.positionUsd"/>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="12" :offset="1" style="display: flex">
                    <el-tooltip class="item" effect="dark" content="单个Symbol，每次下单最大成交美元价值" placement="top-start" :open-delay="666">
                      <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.singleOpenPositionUsd"/>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">杠杆配置</span>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">是否平仓</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-tooltip class="item" effect="dark" content="所有交易对的杠杆数配置" placement="top-start" :open-delay="666">
                      <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.leverage"/>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="12" :offset="1" style="display: flex">
                    <el-select class="select-symbol" style="width: 100%" v-model="ins.strategyConfig.executeLiquidation" placeholder="请选择">
                      <el-option
                          v-for="item in [{value: true, label: 'CLOSE ALL'}, {value: false, label: 'NO'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 8px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">净值告警线</span>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">告警收件人</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-tooltip class="item" effect="dark" content="净值告警线" placement="top-start" :open-delay="666">
                      <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.warningMaxDrawdown"/>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="12" :offset="1" style="padding-right: 0">
                    <el-select class="select-symbol" filterable style="width: 100%" v-model="ins.strategyConfig.alertList" multiple placeholder="请选择告警收件人">
                      <el-option
                          v-for="item in alertList"
                          :key="item.display"
                          :label="item.display"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row style="margin-top: 20px">
                  <el-card class="black-card"
                           style="border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #1c2128"
                           body-style="padding: 10px" shadow="none">
                    <el-row>
                      <el-col :span="8" style="text-align: left">
                        <span class="dark-mode-font" style="color: white">Position USD Value: ${{ getReport(ins).totalUSD.toFixed(2) }}</span>
                      </el-col>
                      <el-col :span="8" :offset="8" style="text-align: right">
                        <span class="dark-mode-font" style="color: white">Position USD Exposure: ${{ getReport(ins).totalMargin.toFixed(2) }}</span>
                      </el-col>
                    </el-row>
                  </el-card>
                  <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128"
                           shadow="none" body-style="padding: 4px 10px 3px 10px">
                    <el-row>
                      <el-col :span="7">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">Platform</span>
                      </el-col>
                      <el-col :span="5">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: left;text-align: right;font-size: 12px;color:darkgray ">Symbol</span>
                      </el-col>
<!--                      <el-col :span="4">-->
<!--                      <span class="dark-mode-font"-->
<!--                            style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">Entry Price</span>-->
<!--                      </el-col>-->
<!--                      <el-col :span="4">-->
<!--                      <span class="dark-mode-font"-->
<!--                            style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">Real Price</span>-->
<!--                      </el-col>-->
                      <el-col :span="5">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">Target Position</span>
                      </el-col>
                      <el-col :span="7">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">Real Position</span>
                      </el-col>
                    </el-row>
                  </el-card>
                  <el-card class="black-card" style="border-radius: 0;border-top: 0;max-height: 224px;overflow-y: auto"
                           shadow="none" body-style="padding: 0">
                    <div v-if="getReport(ins).infoList.length > 0">
                      <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0"
                               body-style="padding:0" v-for="(p, index) in getReport(ins).infoList" :key="index">
                        <el-row style="height: 25px;border-bottom: 1px solid #2e3033;padding: 3px 10px 3px 10px">
                          <el-col :span="7">
                          <span class="dark-mode-font"
                                style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{
                              p['platform']
                            }}({{p['symbolType'].replaceAll('USD_CONTRACT', 'UM').replaceAll('COIN_CONTRACT', 'CM')}})</span>
                          </el-col>
                          <el-col :span="5">
                          <span class="dark-mode-font"
                                style="display: inline-block;float: left;text-align: right;font-size: 12px;color:white ">{{
                              p['symbol']
                            }}</span>
                          </el-col>
<!--                          <el-col :span="4">-->
<!--                          <span class="dark-mode-font"-->
<!--                                style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{-->
<!--                              Number(Number(p['position']['entryPrice']).toFixed(5))-->
<!--                            }}</span>-->
<!--                          </el-col>-->
<!--                          <el-col :span="4">-->
<!--                          <span class="dark-mode-font"-->
<!--                                :style="'display: inline-block;float: right;text-align: right;font-size: 12px;color:' + (p['position']['position'] > 0 ? (Number(p['realPosition']['entryPrice']) > Number(p['position']['entryPrice']) ? '#ef5350' : '#0ecb81') : (Number(p['realPosition']['entryPrice']) < Number(p['position']['entryPrice']) ? '#ef5350' : '#0ecb81')) + ''">{{-->
<!--                              Number(Number(p['realPosition']['entryPrice']).toFixed(5))-->
<!--                            }}</span>-->
<!--                          </el-col>-->
                          <el-col :span="5">
                          <span class="dark-mode-font"
                                style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                              p['position']['position']
                            }}</span>
                          </el-col>
                          <el-col :span="7">
                          <span class="dark-mode-font"
                                :style="'display: inline-block;float: right;text-align: right;font-size: 12px;color:' + (p['position']['position'] !== p['realPosition']['position'] ? 'orange' : 'white') + ''">{{
                              p['realPosition']['position']
                            }}</span>
                          </el-col>
                        </el-row>
                      </el-card>
                    </div>
                    <div v-if="getReport(ins).infoList.length === 0">
                      <el-row
                          style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                      <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i
                          class="el-icon-s-order"/> EMPTY ...</span>
                      </el-row>
                    </div>
                  </el-card>
                </el-row>
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="12">
                    <el-button class="dark-mode-btn"
                               style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px 0 0 0;border-right: 0"
                               @click="reset(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-c-scale-to-original" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="12">
                    <el-button class="dark-mode-btn"
                               style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 3px 0 0"
                               @click="deleteIns(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-delete"
                        style="font-size: 16px"/>
                    </el-button>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin: -10px auto auto;">
                  <el-col :span="24">
                    <el-button class="dark-mode-btn"
                               style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 0 3px 3px;border-top: 0"
                               @click="save(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-check"
                        style="font-size: 16px"/>
                    </el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <el-collapse class="trialpha-collapse" v-model="createCollapse" style="border-top: 0">
            <el-collapse-item class="trialpha-collapse">
              <template slot="title">
                <i class="el-icon-menu" style="margin-left: 2px;color: #0ecb81;margin-top: 1px"/><h4
                  class="dark-mode-font" style="margin-left: 8px;padding-top: 6px;color: white">Create New Instance</h4>
              </template>
              <el-row style="width: 98%;margin: 20px auto 0 auto">
                <el-col :span="16" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">External URL</span>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                <el-col :span="24" style="padding-right: 0">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.externalSignalUrl"/>
                </el-col>
              </el-row>
              <el-row style="height: 88px;overflow-y: auto">
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="24">
                    <el-button class="dark-mode-btn"
                               style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px"
                               @click="add(instance)" :disabled="rendering" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-check"
                        style="font-size: 16px"/>
                    </el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-row>
      </template>
      <trade-tools ref="open-tools"></trade-tools>
    </el-skeleton>
  </div>
</template>

<script>
import {Message} from "element-ui";
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import ConfirmProvider from "@/components/ConfirmProvider";
import TradeTools from "@/pages/controlPanel/accountMgnt/strategies/openTask/ftx/TradeTools";
import TrialphaSk from "@/components/TrialphaSk";
import GeneralApiService from "@/api/trade/GeneralApiService";
import SymbolInfoService from "@/utils/SymbolInfoService";
import ExchangeInformations from "@/const/ExchangeInformations";

export default {
  name: "ExternalStrategy",
  components: {TrialphaSk, TradeTools},
  props: {
    dto: Object
  },
  data: function () {
    return {
      exchangeInfo: {
        BINANCE: {
          logo: ExchangeInformations.exchangeInfos.BINANCE.icon,
          fontColor: '#f5bc2f'
        },
        BITMEX: {
          logo: ExchangeInformations.exchangeInfos.BITMEX.icon,
          fontColor: '#5b69b1'
        },
        DERIBIT: {
          logo: ExchangeInformations.exchangeInfos.DERIBIT.icon,
          fontColor: '#04cfbe'
        },
        FTX: {
          logo: ExchangeInformations.exchangeInfos.FTX.icon,
          fontColor: '#67ccdf'
        },
        OKX: {
          logo: ExchangeInformations.exchangeInfos.OKX.icon,
          fontColor: '#67ccdf'
        },
        BYBIT: {
          logo: ExchangeInformations.exchangeInfos.BYBIT.icon,
          fontColor: '#67ccdf'
        }
      },
      platforms: [GeneralApiService.PLATFORM.BINANCE, GeneralApiService.PLATFORM.OKX],
      symbolTypes: ['SPOT', 'USD_CONTRACT', 'COIN_CONTRACT', 'OPTIONS'],
      processing: false,
      rendering: true,
      activeNames: ['1'],
      createCollapse: ['1'],
      instance: {},
      instanceList: [],
      accountInstance: {},
      allPrice: {},
      allSymbols: [],
      alertList: [
        {
          display: 'Nick',
          value: 'nick@forthtech.org'
        },
        {
          display: 'Lethe',
          value: 'lethe.xie@forthtech.org'
        },
        {
          display: 'Petrus',
          value: 'petrus.zheng@forthtech.org'
        },
        {
          display: 'Louie',
          value: 'louie@forthtech.org'
        },
        {
          display: 'Jack',
          value: 'tobitmaker@gmail.com'
        },
        {
          display: 'Darren',
          value: 'darren@forthtech.org'
        },
        {
          display: 'Vincent',
          value: 'vincent.wang@forthtech.org'
        },
        {
          display: 'Shawn',
          value: 'junbo0711@outlook.com'
        }
      ]
    }
  },
  created() {
    this.closeReadOnly(true);
  },
  methods: {
    closeReadOnly(val) {
      this.$nextTick(() => {
        if (val) {
          this.$el.querySelectorAll('.chose-symbol.select-symbol .el-input__inner').forEach((item) => {
            item.removeAttribute('readonly');
          })
        }
      })
    },
    openTradeTools(ins) {
      this.$nextTick(function () {
        this.$refs['open-tools'].renderFtx(ins);
      });
    },
    render: function (accountInstance) {
      let that = this;
      that.rendering = true;
      that.accountInstance = accountInstance;
      that.instance = JSON.parse(JSON.stringify(that.dto.strategyConfig));
      StrategyInstanceService.query(that.accountInstance.instanceId, that.dto.strategyName, function (data) {
        that.instanceList = data.strategyInsList;
        that.rendering = false;
      }, function () {
        that.rendering = false;
      });
    },
    add: function (ins) {
      let that = this;
      that.processing = true;
      StrategyInstanceService.create({
        insId: this.accountInstance.instanceId,
        strategyName: this.dto.strategyName,
        config: JSON.stringify(ins)
      }, function () {
        Message.success('已成功创建新的实例');
        that.processing = false;
        setTimeout(() => that.render(that.accountInstance), 888);
      }, function () {
        that.processing = false;
      });
    },
    innerNameResolve: function (platform, baseAsset, quoteAsset) {
      if (platform === GeneralApiService.PLATFORM.BINANCE) {
        return baseAsset + quoteAsset;
      } else if (platform === GeneralApiService.PLATFORM.OKX) {
        return baseAsset + '-' + quoteAsset;
      }
    },
    getPrice: function (ins) {
      return SymbolInfoService.getSymbolPrice(ins.symbolPlatform, GeneralApiService.SYMBOL_TYPE.SPOT, this.innerNameResolve(ins.symbolPlatform, ins.baseAsset, ins.quoteAsset));
    },
    reset: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认重置', '该操作将会重置所有非配置的数据', function () {
        that.processing = true;
        StrategyInstanceService.reset(ins.sid, function () {
          that.processing = false;
          Message.success('已成功重置');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    deleteIns: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认删除', '该操作将会删除该实例，此操作无法恢复', function () {
        that.processing = true;
        StrategyInstanceService.deleteIns(ins.sid, function () {
          that.processing = false;
          Message.success('已成功删除');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    save: function (ins) {
      let that = this;
      that.processing = true;
      StrategyInstanceService.update({
        sid: ins.sid,
        strategyName: ins.strategyName,
        config: (ins.strategyConfig),
        instanceEntity: {
          id: that.accountInstance.instanceId
        },
        isActive: ins.isActive
      }, function () {
        that.processing = false;
        Message.success('已成功更新');
        that.render(that.accountInstance);
      }, function () {
        that.processing = false;
      });
    },
    getReport: function (ins) {
      let totalUSD = 0;
      let totalMargin = 0;
      let infoList = [];
      for (const platform in ins.strategyConfig.targetPosition) {
        let symbolTypePositionMap = ins.strategyConfig.targetPosition[platform];
        for (const symbolType in symbolTypePositionMap) {
          let positionInfoMap = symbolTypePositionMap[symbolType];
          for (const symbol in positionInfoMap) {
            let realPosition = {};
            try {
              realPosition = ins.strategyConfig.currentPosition[platform][symbolType][symbol];
              totalUSD += Math.abs(realPosition.position) * realPosition.entryPrice;
              totalMargin += (realPosition.position) * realPosition.entryPrice;
            } catch (e) {
              console.error(e)
            }
            infoList.push({
              platform: platform,
              symbolType: symbolType,
              symbol: symbol,
              position: positionInfoMap[symbol],
              realPosition: realPosition
            })
          }
        }
      }
      return {
        totalUSD: totalUSD,
        totalMargin: totalMargin,
        infoList: infoList
      };
    }
  }
}
</script>

<style scoped>

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-divider.info {
  width: 80%;
  border-top: 1px solid #444c56;
}

.el-divider__text {
  color: white;
  background-color: #444c56;
}

.select-symbol .el-input__inner {
  color: white;
  height: 35px !important;
  line-height: 35px;
  width: 100%;
  padding: 0 10px 0 10px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
  outline: none;
}

.select-symbol.el-select .el-input.is-focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

</style>