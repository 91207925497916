<template>
  <div class="trialpha-full-sub-page">
    <div style="padding-bottom: 100px;margin-top: 40px">
      <el-row style="margin-top: 0">
        <el-col :span="24" :offset="0">
          <el-card class="black-card" shadow="none" body-style=";padding: 25px 0 25px 0"
                   style="background-color:rgba(255,255,255,0.01);border-left: 0;border-right: 0;border-top: 0">
            <el-row>
              <el-col :span="12" :offset="6">
                <label for="upload-img">
                  <el-avatar style="width: 100px;height:100px;cursor: pointer;background-color: rgba(0, 0, 0, 0)"
                             :src="avatar" @error="true" :key="avatar"
                             fit="fill">
                    <el-avatar
                        style="width: 100px;height:100px;background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);font-size: 35px;padding-top: 30px;color: rgba(255,255,255, 0.568)">
                      <B>
                        <i class="el-icon-camera-solid" style="cursor: pointer"/>
                      </B></el-avatar>
                  </el-avatar>
                </label>
                <input id="upload-img" type="file" style="display:none" @change="fileChange" accept="image/*"/>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
              <el-col :span="12" :offset="6">
                <span class="dark-mode-font" style="font-size: 20px;color: white">{{
                    user.userInfo.alias.replace(/^\S/, s => s.toUpperCase())
                  }} </span>
                <span class="dark-mode-font hidden-xs-only"
                      style="color: #949da5;font-size: 12px">({{ $i('permission.' + user.userInfo.role.role) }})</span>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
              <el-col :span="20" :offset="2">
                <span class="dark-mode-font" style="color: white"><i class="el-icon-time"
                                                                     style="color: white"/> {{ timeInfo }} </span>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
              <el-col :span="20" :offset="2">
                <i class="el-icon-location" style="color: #b8f572"/><span class="dark-mode-font" style="color: white"> {{
                  address
                }} </span>
                <span class="dark-mode-font" style="color: white">{{ $i('controlPanelHome.lastLoginTime') }}</span>
                <span class="dark-mode-font"
                      style="color: white"> {{ new Date(user.lastLoginActivity.activityTime * 1000) }} </span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <div style="margin: auto;max-width: 2068px">
        <div>
          <el-row class="show-4-symbol" style="margin-top: 10px">
            <el-col :span="20" :offset="2">
              <el-row>
                <el-col :span="6" style="padding: 10px 10px 10px 0">
                  <coin-simple-info :symbol="'BTCUSDT'"></coin-simple-info>
                </el-col>
                <el-col :span="6" style="padding: 10px">
                  <coin-simple-info :symbol="'ETHUSDT'"></coin-simple-info>
                </el-col>
                <el-col :span="6" style="padding: 10px">
                  <coin-simple-info :symbol="'BNBUSDT'"></coin-simple-info>
                </el-col>
                <el-col :span="6" style="padding: 10px 0 10px 10px">
                  <coin-simple-info :symbol="'APEUSDT'"></coin-simple-info>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="show-3-symbol" style="margin-top: 10px">
            <el-col :span="20" :offset="2">
              <el-row>
                <el-col :span="8" style="padding: 10px 10px 10px 0">
                  <coin-simple-info :symbol="'BTCUSDT'"></coin-simple-info>
                </el-col>
                <el-col :span="8" style="padding: 10px">
                  <coin-simple-info :symbol="'ETHUSDT'"></coin-simple-info>
                </el-col>
                <el-col :span="8" style="padding: 10px 0 10px 10px">
                  <coin-simple-info :symbol="'SOLUSDT'"></coin-simple-info>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="show-2-symbol" style="margin-top: 10px">
            <el-col :span="20" :offset="2">
              <el-row>
                <el-col :span="12" style="padding: 10px 10px 10px 0">
                  <coin-simple-info :symbol="'BTCUSDT'"></coin-simple-info>
                </el-col>
                <el-col :span="12" style="padding: 10px 0 10px 10px">
                  <coin-simple-info :symbol="'ETHUSDT'"></coin-simple-info>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="show-1-symbol" style="margin-top: 10px">
            <el-col :span="20" :offset="2">
              <el-row>
                <el-col :span="24" style="padding: 10px 0 10px 0">
                  <coin-simple-info :symbol="'BTCUSDT'"></coin-simple-info>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row v-auth:homePageNormalUser style="margin-top: 10px">
            <el-col :span="20" :offset="2">
          <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 12px">
            <i class="el-icon-s-flag"> Oneport 第二只 USD 基金现已开始正式开放申购</i>
          </span>
            </el-col>
          </el-row>
        </div>
        <div v-auth:homePageNormalUser>
          <div style="height: 10px;width: 100%"></div>
        </div>
        <el-row style="margin-top: 10px">
          <el-col :span="20" :offset="2" style="display: flex">
            <instance-statics ref="instance-statics"></instance-statics>
          </el-col>
        </el-row>
        <el-row class="show-more-1000" style="margin-top: 20px">
          <el-col :span="12" :offset="2">
            <el-card class="black-card" body-style="padding: 20px" style="border: 0;height: 208px">
              <el-row>
                <el-col :span="24">
                    <span class="dark-mode-font"
                          style="display: inline-block;float: left;font-size: 18px;color: #FFFFFF">{{ $i('customerProfit.totalEarning') }}</span>
                </el-col>
              </el-row>
              <el-row>
                <customer-profit ref="customer-profit-more-1000"></customer-profit>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="8" style="padding-left: 20px">
            <risk-free-profit></risk-free-profit>
          </el-col>
        </el-row>
        <el-row class="show-less-1000" style="margin-top: 20px">
          <el-row>
            <el-col :span="20" :offset="2">
              <el-card class="black-card" body-style="padding: 20px" style="border: 0;">
                <el-row>
                  <el-col :span="24">
                    <span class="dark-mode-font"
                          style="display: inline-block;float: left;font-size: 18px;color: #FFFFFF">{{ $i('customerProfit.totalEarning') }}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <customer-profit ref="customer-profit-less-1000"></customer-profit>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="20" :offset="2">
              <risk-free-profit></risk-free-profit>
            </el-col>
          </el-row>
        </el-row>
        <div v-auth:homePageAdmin>
          <div class="hidden-sm-and-down" style="padding-left: 10px">
            <el-row style="margin-top: 25px;">
              <el-col :span="11" :offset="1" style="padding-right: 28px;">
                <el-row>
                  <el-col :span="22" :offset="2">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: left;margin-left: 3px;color: #FFFFFF"><b>{{
                      $i('controlPanelHome.allAccountOperation')
                    }}</b></span>
                    <el-input class="trialpha-dark-input trialpha-dark-input-para search-user-input"
                              style="margin-top: -5px;margin-left: 10px;width: 168px;float: left;border-radius: 3px 3px 0 0"
                              placeholder="Filter by name" v-model="filterUser">
                      <template slot="prepend"><span
                          style="font-size: 12px;font-weight: bolder;color: #ffffff;">Name</span></template>
                    </el-input>
                    <el-button class="dark-mode-btn"
                               style="height: 35px;line-height: 35px;padding: 0;width: 45px;margin-left: 5px;float: left;border-radius: 3px;margin-top: -5px"
                               @click="loadActivities">
                      <i class="el-icon-s-promotion" style="font-size: 15px"></i></el-button>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row style="margin-top: 0">
              <el-col :span="11" :offset="1" style="padding-right: 28px">
                <el-row style="margin-top: -10px">
                  <el-col :span="22" :offset="2">
                    <el-divider></el-divider>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11" :offset="1" style="padding-right: 28px;margin-bottom: 60px">
                <el-row style="margin-top: -23px;height: 100%">
                  <el-col :span="22" :offset="2" style="height: 100%">
                    <el-skeleton animated :loading="loadingObj.activityLoading">
                      <template slot="template">
                        <trialpha-sk
                            :style="'margin-top: 20px;width: 60%;float: left'"></trialpha-sk>
                        <trialpha-sk
                            :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                        <trialpha-sk
                            :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                        <trialpha-sk
                            :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                        <trialpha-sk
                            :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
                      </template>
                      <template>
                        <div class="hidden-sm-and-down" id="infinite-activities-pc"
                             v-infinite-scroll="loadMoreActivitiesFromPC"
                             :infinite-scroll-disabled="loadingObj.disableLoad" style="height: 100%">
                          <el-card class="black-card trialpha-activity-dark-card" shadow="none"
                                   body-style="padding: 15px"
                                   v-for="activity in activities" :key="activity.id">
                            <el-row class="dark-mode-font">
                              <el-col :span="2">
                                <el-avatar :size="30" :src="basicAvatarUrl + activity.user.id + '-avatar.png'"
                                           style=";background-color: rgba(0, 0, 0, 0)"
                                           @error="true" :key="basicAvatarUrl + activity.user.id + '-avatar.png'"
                                           fit="fill">
                                  <el-avatar icon="el-icon-s-custom" :size="30"
                                             style="color: cornflowerblue;font-size: 13px;background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);"></el-avatar>
                                </el-avatar>
                              </el-col>
                              <el-col :span="16">
                            <span
                                style="display: inline-block;float: left;font-size: 15px;color: white; margin-top: 3px;margin-left: 10px">{{
                                activity.user.username
                              }} <span style="font-size: 12px;color: #adbac7"> ({{
                                  $i('permission.' + activity.user.role.role)
                                }})</span></span>
                              </el-col>
                              <el-col :span="6">
                              <span style="display: inline-block;float: right;font-size: 12px">{{
                                  timeUtils.getTimeDiffString(activity.operation.updateTime)
                                }}</span>
                              </el-col>
                            </el-row>
                            <el-row class="dark-mode-font" style="margin-top: 5px">
                              <el-col :span="22" :offset="2">
                                <i :class="operatorIconMap[activity.operation.operate]"
                                   :style="'color: ' + operatorIconColorMap[activity.operation.operate] + ';font-size: 18px;float: left;margin-left: 10px;padding-top: 4px'"></i>
                                <span style="float: left;margin-left: 10px;font-size: 12px;margin-top: 5px"> {{
                                    activity.operation.operate
                                  }}</span>
                                <span style="float: left;margin-left: 10px;font-size: 12px;margin-top: 5px"><a
                                    style="cursor: pointer" @click="transTo(activity.operation.instanceName)"><b
                                    class="trialpha-url-font">{{
                                    activity.operation.instanceName
                                  }}</b></a></span>
                                <span
                                    style="float: left;margin-left: 10px;font-size: 12px;margin-top: 5px;text-align: left"><span
                                    style="color: white">{{ activity.operation.details }}</span></span>
                              </el-col>
                            </el-row>
                          </el-card>
                        </div>
                      </template>
                    </el-skeleton>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="10" style="padding-left: 28px;margin-top: -28px">
                <el-row>
                  <el-col :span="14">
                    <el-card class="black-card" shadow="none" style="width: 98%;height: 100px">
                      <el-row style="height: 55px;width: 100%;padding-top: 12px;">
                        <p class="dark-mode-font" style="color: white;font-size: 22px">
                          <b>{{ Number.prototype.toFormatNum(Number(tradeVol.totalTradeVol.toFixed(2))) }}</b></p>
                      </el-row>
                      <el-row>
                      <span style="display: inline-block;float: right;margin-right: -10px;color: white;font-size: 12px">{{
                          $i('controlPanelHome.tradeAmounts')
                        }}</span>
                      </el-row>
                    </el-card>
                  </el-col>
                  <el-col :span="10">
                    <el-card class="black-card" shadow="none" style="width: 98%;height: 100px">
                      <el-row style="height: 55px;width: 100%;padding-top: 12px;">
                        <p class="dark-mode-font" style="color: white;font-size: 22px">
                          <b>{{ Number.prototype.toFormatNum(Number(tradeVol.monthTradeVol.toFixed(2))) }}</b></p>
                      </el-row>
                      <el-row>
                      <span style="display: inline-block;float: right;margin-right: -10px;color: white;font-size: 12px">{{
                          $i('controlPanelHome.tradeAmount30')
                        }}</span>
                      </el-row>
                    </el-card>
                  </el-col>
                </el-row>
                <el-row style="margin-top: 1%">
                  <el-card class="black-card" shadow="none" style="width: 99.5%;height: 468px;padding-bottom: 0">
                    <trade-vol-chart id="trade_vol_id_admin" ref="trade_vol_ref_admin" :color="'#9383e2'" width="3"
                                     style="width: 100%; height: 428px"></trade-vol-chart>
                  </el-card>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <div class="hidden-md-and-up" style="margin-bottom: 0">
            <el-row style="margin-top: 25px;">
              <el-col :span="24" :offset="0" style="padding-right: 16px;">
                <el-row>
                  <el-col :span="23" :offset="1">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: left;margin-left: 3px;color: #FFFFFF"><b>{{
                      $i('controlPanelHome.allAccountOperation')
                    }}</b></span>
                    <el-input class="trialpha-dark-input trialpha-dark-input-para search-user-input"
                              style="margin-top: -5px;margin-left: 10px;width: 168px;float: left;border-radius: 3px 3px 0 0"
                              placeholder="Filter by name" v-model="filterUser">
                      <template slot="prepend"><span
                          style="font-size: 12px;font-weight: bolder;color: #ffffff;">Name</span></template>
                    </el-input>
                    <el-button class="dark-mode-btn"
                               style="height: 35px;line-height: 35px;padding: 0;width: 40px;margin-left: 5px;float: left;border-radius: 3px;margin-top: -5px"
                               @click="loadActivities">
                      <i class="el-icon-s-promotion" style="font-size: 15px"></i></el-button>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row style="margin-top: 0">
              <el-col :span="22" :offset="1" style="padding-right: 0">
                <el-row style="margin-top: -10px">
                  <el-col :span="24" :offset="0">
                    <el-divider></el-divider>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="22" :offset="1" style="padding-right: 0;margin-bottom: 60px">
                <el-row style="margin-top: -23px">
                  <el-col :span="24" :offset="0">
                    <el-skeleton animated :loading="loadingObj.activityLoading">
                      <template slot="template">
                        <trialpha-sk
                            :style="'margin-top: 20px;width: 60%;float: left'"></trialpha-sk>
                        <trialpha-sk
                            :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                        <trialpha-sk
                            :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                        <trialpha-sk
                            :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                        <trialpha-sk
                            :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
                      </template>
                      <template>
                        <div v-infinite-scroll="loadMoreActivitiesFromM"
                             :infinite-scroll-disabled="loadingObj.disableLoad">
                          <el-card class="black-card trialpha-activity-dark-card" shadow="none"
                                   body-style="padding: 10px"
                                   v-for="activity in activities" :key="activity.id">
                            <el-row class="dark-mode-font">
                              <el-col :span="16">
                          <span
                              style="display: inline-block;float: left;font-size: 15px;color: white; margin-top: 3px;margin-left: 10px">{{
                              activity.user.username
                            }} <span style="font-size: 12px;color: #adbac7"> ({{
                                $i('permission.' + activity.user.role.role)
                              }})</span></span>
                              </el-col>
                              <el-col :span="8">
                              <span style="display: inline-block;float: right;font-size: 12px;margin-top: 6px">{{
                                  timeUtils.getTimeDiffString(activity.operation.updateTime)
                                }}</span>
                              </el-col>
                            </el-row>
                            <el-row class="dark-mode-font" style="margin-top: 5px">
                              <el-col :span="22" :offset="0">
                                <i :class="operatorIconMap[activity.operation.operate]"
                                   :style="'color: ' + operatorIconColorMap[activity.operation.operate] + ';font-size: 18px;float: left;margin-left: 10px;padding-top: 4px'"></i>
                                <span style="float: left;margin-left: 10px;font-size: 12px;margin-top: 5px"> {{
                                    activity.operation.operate
                                  }}</span>
                                <span style="float: left;margin-left: 10px;font-size: 12px;margin-top: 5px"><a href="#"><b
                                    class="trialpha-url-font">{{ activity.operation.instanceName }}</b></a></span>
                                <span
                                    style="float: left;margin-left: 10px;font-size: 12px;margin-top: 5px;text-align: left"><span
                                    style="color: white">{{ activity.operation.details }}</span></span>
                              </el-col>
                            </el-row>
                          </el-card>
                        </div>
                      </template>
                    </el-skeleton>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavService from "@/const/NavService";
import UserService from "@/api/user/UserService";
import TimeService from "@/utils/TimeService";
import MapService from "@/utils/MapService";
import TradeVolChart from "@/pages/controlPanel/home/TradeVolChart";
import InstanceService from "@/api/trade/InstanceService";
import TradeVolService from "@/api/trade/TradeVolService";
import UserPermissionService from "@/api/user/UserPermissionService";
import NProgress from 'muse-ui-progress';
import {AwsUpdateAvatar} from "@/utils/UploadFile";
import RouterPath from "@/router/RouterPath";
import {Message} from "element-ui";
import CoinSimpleInfo from "@/pages/controlPanel/home/CoinSimpleInfo";
import RiskFreeProfit from "@/pages/controlPanel/home/RiskFreeProfit";
import CustomerProfit from "@/pages/controlPanel/home/CustomerProfit";
import InstanceStatics from "@/pages/controlPanel/home/InstanceStatics";
import TrialphaSk from "@/components/TrialphaSk";

export default {
  name: "ControlPanelHome",
  components: {TrialphaSk, InstanceStatics, CustomerProfit, RiskFreeProfit, CoinSimpleInfo, TradeVolChart},
  data: function () {
    return {
      instanceData: {},
      loadingObj: {
        activityLoading: true,
        loadingMore: false,
        disableLoad: false
      },
      filterUser: '',
      that: this,
      time: new Date(),
      user: UserService.getUserObj(),
      navBar: NavService.navBars,
      timeInterval: {},
      timeInfo: 'Mon 00:00:00',
      timeUtils: TimeService,
      address: 'Shanghai',
      activities: [],
      operatorIconMap: {
        'UPDATE': 'el-icon-share',
        'DELETE': 'el-icon-s-release',
        'CREATE': 'el-icon-s-claim'
      },
      operatorIconColorMap: {
        'UPDATE': '#c7e287',
        'DELETE': '#c84057',
        'CREATE': '#40c87d'
      },
      userNotification: {
        errPosition: true,
        message: true,
        groupMessage: false
      },
      activitiesTimer: {},
      tradeVolTimer: {},
      tradeVol: {
        totalTradeTimes: 0,
        totalTradeVol: 0,
        monthTradeVol: 0,
        lastNDayVol: []
      },
      page: 0,
      size: 20,
      windowWidth: document.documentElement.clientWidth,
      basicAvatarUrl: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/web/avatar/',
      avatar: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/web/avatar/' + UserService.getUserObj().userInfo.id + '-avatar.png'
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth;
        this.windowWidth = window.fullWidth;
      })();
    };
  },
  created() {
    NProgress.start();
    let that = this;
    window.$crisp.push(["set", "session:data", [[["role", this.$i('permission.' + this.user.userInfo.role.role)], ['email', this.user.userInfo.email], ['username', this.user.userInfo.username], ['alias', this.user.userInfo.alias], ['avatar', this.avatar]]]]);
    window.$crisp.push(["set", "user:email", [this.user.userInfo.email]]);
    window.$crisp.push(["set", "user:nickname", [this.user.userInfo.alias]]);
    window.$crisp.push(["set", "user:avatar", [this.avatar]]);
    this.loadActivities();
    this.timeInterval = setInterval(() => {
      that.timeInfo = TimeService.getSimpleTimeInfo();
    }, 1000);
    MapService.getLatLocationByIP(function (data) {
      let province = data.province;
      let city = data.city;
      if (province === city) {
        that.address = province;
      } else {
        that.address = province + ' ' + city;
      }
    });
    this.loadActivities();
    this.queryTradeVol();
    InstanceService.query(function (data) {
      that.instanceData = data;
      that.$refs['customer-profit-more-1000'].activePanel(data);
      that.$refs['customer-profit-less-1000'].activePanel(data);
      that.$refs['instance-statics'].activePanel(data);
    });
    this.activitiesTimer = setInterval(this.loadActivities, 1000 * 60);
    this.tradeVolTimer = setInterval(this.queryTradeVol, 1000 * 60);
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    clearInterval(this.activitiesTimer);
    clearInterval(this.tradeVolTimer);
  },
  methods: {
    transTo(name) {
      this.$router.push({
        path: RouterPath.router.controlPanelChild.accountMgnt,
        query: {
          instanceName: name
        }
      }).catch(err => {
        console.log(err)
      });
    },
    cutDown(obj) {
      console.log(obj);
    },
    fileChange(e) {
      let file = e.target.files[0];
      let that = this;
      if (e.target.files[0].type.startsWith('image/') && e.target.files[0].size < 1024000) {
        AwsUpdateAvatar(UserService.getUserObj().userInfo.id + '-avatar.png', file).then(res => {
          that.avatar = 'https://empty';
          that.avatar = res.Location;
          that.$router.go(0);
        });
      } else {
        Message.error('请确认文件类型正确，且小于1Mb');
      }
    },
    changeDrawer: function (status) {
      if (status) {
        this.$nextTick(function () {
          this.$refs['taskConfig'].openDrawer();
        });
      }
    },
    loadActivities: function () {
      let that = this;
      that.page = 1;
      if (UserPermissionService.getFeaturePermission('homePageAdmin') === UserPermissionService.permission_type.ALLOWED) {
        InstanceService.getAudit(that.page, that.size, function (data) {
          that.activities = data.filter((item) => {
            if (item.user.username.toLowerCase().indexOf(that.filterUser.toLowerCase()) > -1) {
              return item;
            }
          });
          that.loadingObj.activityLoading = false;
          that.loadingObj.disableLoad = data.length < that.size;
        }, function () {
          that.loadingObj.activityLoading = false;
        });
      } else {
        that.loadingObj.disableLoad = true;
        NProgress.done();
      }
    },
    loadMoreActivitiesFromPC: function () {
      if (this.windowWidth > 1000) {
        let that = this;
        if (!that.loadingObj.loadingMore) {
          clearInterval(that.activitiesTimer);
          that.activitiesTimer = setInterval(this.loadActivities, 1000 * 60);
          that.page = that.page + 1;
          that.loadingObj.loadingMore = true;
          InstanceService.getAudit(that.page, that.size, function (data) {
            data.forEach((obj) => {
              if (obj.user.username.toLowerCase().indexOf(that.filterUser.toLowerCase()) > -1) {
                that.activities.push(obj);
              }
            });
            that.loadingObj.disableLoad = data.length < that.size;
            that.loadingObj.loadingMore = false;
          }, function () {
            that.loadingObj.loadingMore = false;
          });
        }
      }
    },
    loadMoreActivitiesFromM: function () {
      if (this.windowWidth < 1000) {
        let that = this;
        if (!that.loadingObj.loadingMore) {
          clearInterval(that.activitiesTimer);
          that.activitiesTimer = setInterval(this.loadActivities, 1000 * 60);
          that.page = that.page + 1;
          that.loadingObj.loadingMore = true;
          InstanceService.getAudit(that.page, that.size, function (data) {
            data.forEach((obj) => {
              if (obj.user.username.toLowerCase().indexOf(that.filterUser.toLowerCase()) > -1) {
                that.activities.push(obj);
              }
            });
            that.loadingObj.disableLoad = data.length < that.size;
            that.loadingObj.loadingMore = false;
          }, function () {
            that.loadingObj.loadingMore = false;
          });
        }
      }
    },
    queryTradeVol: function () {
      let that = this;
      if (UserPermissionService.getFeaturePermission('homePageAdmin') === UserPermissionService.permission_type.ALLOWED) {
        TradeVolService.query(30, [], function (data) {
          NProgress.done();
          that.tradeVol = data;
          that.$nextTick(function () {
            if (UserPermissionService.getFeaturePermission('homePageAdmin') === UserPermissionService.permission_type.ALLOWED) {
              that.$refs['trade_vol_ref_admin'].render(that.tradeVol.lastNDayVolDateString);
            } else if (UserPermissionService.getFeaturePermission('homePageNormalUser') === UserPermissionService.permission_type.ALLOWED) {
              that.$refs['trade_vol_ref'].render(that.tradeVol.lastNDayVolDateString);
            }
          });
        }, function () {
          NProgress.done();
        });
      } else {
        NProgress.done();
      }
    }
  }
}
</script>

<style scoped>

.bodyInner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  background: #22272e;
}

.light_box {
  width: 100%;
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  transform-origin: 100% 0;
  transform: rotate(5deg);
}

.light {
  flex-grow: 1;
  height: 100%;
  transform-origin: 100% 0;
  filter: blur(88px);
  background-image: linear-gradient(
      0deg,
      transparent,
      hsla(240, 31%, 58%, 0.3) 50%,
      transparent,
      hsla(214, 6%, 23%, 0.4) 30%,
      transparent,
      hsla(212, 14%, 18%, 0.6) 60%
  );
}

.el-divider {
  background-color: #444c56;
  height: 2px;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 0;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(119, 109, 109, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(119, 109, 109, 0.5);
}

@media only screen and (min-width: 800px) {
  .show-1-symbol {
    display: none !important
  }
}

@media only screen and (max-width: 800px) {
  .show-2-symbol {
    display: none !important
  }
}

@media only screen and (min-width: 1000px) {
  .show-less-1000 {
    display: none !important
  }
}

@media only screen and (max-width: 1000px) {
  .show-more-1000 {
    display: none !important
  }
}

@media only screen and (min-width: 1200px) {
  .show-2-symbol {
    display: none !important
  }
}

@media only screen and (max-width: 1200px) {
  .show-3-symbol {
    display: none !important
  }
}

@media only screen and (min-width: 1500px) {
  .show-3-symbol {
    display: none !important
  }
}

@media only screen and (max-width: 1500px) {
  .show-4-symbol {
    display: none !important
  }
}
</style>