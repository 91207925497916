import { render, staticRenderFns } from "./FtWalletUpdateWalletDialog.vue?vue&type=template&id=22c5cab4&scoped=true"
import script from "./FtWalletUpdateWalletDialog.vue?vue&type=script&lang=js"
export * from "./FtWalletUpdateWalletDialog.vue?vue&type=script&lang=js"
import style0 from "./FtWalletUpdateWalletDialog.vue?vue&type=style&index=0&id=22c5cab4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22c5cab4",
  null
  
)

export default component.exports