<script type="text/javascript">
import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper"
import UserService from "@/api/user/UserService";

let permission_type = {
  ALLOWED: 'ENABLED',
  HIDE: 'HIDE',
  DISABLED: 'DISABLED'
}

let all_permission = {
  UserPermissionManagement: 'UserPermissionManagement',
  dbWriteApi: 'dbWriteApi',
  homePageAdmin: 'homePageAdmin',
  homePageNormalUser: 'homePageNormalUser',
  exchangeInfo: 'exchangeInfo',
  addAccount: 'addAccount',
  linkAccount: 'linkAccount',
  renameAccount: 'renameAccount',
  resetAccount: 'resetAccount',
  unlinkAccount: 'unlinkAccount',
  deleteAccount: 'deleteAccount',
  configAccount: 'configAccount',
  strategyFilter: 'strategyFilter',
  showError: 'showError',
  showStrategyDescription: 'showStrategyDescription',
  TraCupPanel: 'TraCupPanel',
  crmMgnt: 'crmMgnt',
  cmsMgmt: 'cmsMgmt',
  crmFullPermission: 'crmFullPermission',
  quickPlaceOrder: 'quickPlaceOrder',
  accountInfoPanel: 'accountInfoPanel',
  strategyPanel: 'strategyPanel',
  accountPlan: 'accountPlan',
  tradePanelWrite: 'tradePanelWrite',
  fundingFeeTools: 'fundingFeeTools',
  bindCMS: 'bindCMS',
  settlementPage: 'settlementPage'
}

function syncWebPermissionConfig() {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.userWebPermissionQuery, function (data) {
    let user = UserService.getUserObj();
    user.userInfo.role.featureConfigLists = data;
    UserService.updateUserObj(user);
  });
}

//从后台取
function getFeaturePermission(featureName) {
  let user = UserService.getUserObj();
  let featureConfigLists = user.userInfo.role.featureConfigLists;
  for (let index in featureConfigLists) {
    if (featureConfigLists[index].featureName === featureName) {
      return featureConfigLists[index].permission;
    }
  }
  return permission_type.HIDE;
}

function isAllowed(featureName) {
  return getFeaturePermission(featureName) === permission_type.ALLOWED;
}

function isAllowedAndThen(featureName, then) {
  if (getFeaturePermission(featureName) === permission_type.ALLOWED) {
    then();
  }
}

function isAllowedImportantApiCall(url, then) {
  let uri = url.indexOf('?') > -1 ? url.split('?')[0] : url;
  if (ServiceMapper.db_write_api.indexOf(uri) > -1) {
    if (getFeaturePermission('dbWriteApi') === permission_type.ALLOWED) {
      then();
    }
  } else {
    then();
  }
}

function userPermissionQuery() {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.userPermissionQuery, function (data) {
      console.log(data);
      }, function (err) {
    console.error(err);
      });
}

function updateWebPermissionConfig(req, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.updateWebPermissionConfig, req, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}


export default {
  all_permission,
  userPermissionQuery,
  getFeaturePermission,
  syncWebPermissionConfig,
  permission_type,
  isAllowed,
  isAllowedAndThen,
  isAllowedImportantApiCall,
  updateWebPermissionConfig
};

</script>

<style scoped>
</style>