<script>

import DatafeedApiService from "@/api/trade/DatafeedApiService";
import {Message} from "element-ui";
import GeneralApiService from "@/api/trade/GeneralApiService";
import CoinToPerpNameResolver from "@/utils/CoinToPerpNameResolver";

let BINANCE = {
  SPOT: {},
  PERP: {}
};
let BYBIT = {
  SPOT: {},
  PERP: {}
};
let OKX = {};

let SYMBOL_MODEL_LOAD_LIST = [
  {
    platform: GeneralApiService.PLATFORM.BINANCE,
    symbolType: GeneralApiService.SYMBOL_TYPE.SPOT
  },
  {
    platform: GeneralApiService.PLATFORM.BINANCE,
    symbolType: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT
  },
  {
    platform: GeneralApiService.PLATFORM.BINANCE,
    symbolType: GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT
  },
  // {
  //   platform: GeneralApiService.PLATFORM.BYBIT,
  //   symbolType: GeneralApiService.SYMBOL_TYPE.SPOT
  // },
  // {
  //   platform: GeneralApiService.PLATFORM.BYBIT,
  //   symbolType: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT
  // },
  {
    platform: GeneralApiService.PLATFORM.OKX,
    symbolType: GeneralApiService.SYMBOL_TYPE.ALL
  },
  {
    platform: GeneralApiService.PLATFORM.DERIBIT,
    symbolType: GeneralApiService.SYMBOL_TYPE.ALL
  },
  {
    platform: GeneralApiService.PLATFORM.MEXC,
    symbolType: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT
  },
  {
    platform: GeneralApiService.PLATFORM.DYDX,
    symbolType: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT
  }
];

let SYMBOL_MODEL_MAP = {};

let PLATFORM_SYMBOL_TYPE_PRICE_MAP = {};

function loadData() {
  loadSymbolModel();
  loadSymbolPriceInfo();
}

function loadSymbolPriceInfo() {
  // DatafeedApiService.lastPriceIn24hr('bybit', 'future', '', function (dataBybit) {
  //   if (dataBybit && dataBybit.data && dataBybit.data.symolPrices) {
  //     BYBIT.PERP = dataBybit.data.symolPrices;
  //   }
  // });
  // DatafeedApiService.lastPriceIn24hr('ftx', 'future', '', function (dataFtx) {
  //   if (dataFtx && dataFtx.data && dataFtx.data.symolPrices) {
  //     FTX = dataFtx.data.symolPrices;
  //   }
  // });
  DatafeedApiService.lastPriceIn24hr('okex', 'future', '', function (dataOKX) {
    if (dataOKX && dataOKX.data && dataOKX.data.symolPrices) {
      OKX = dataOKX.data.symolPrices;
    }
  });
  DatafeedApiService.lastPriceIn24hr('binance', 'future', '', function (dataBnPerp) {
    if (dataBnPerp && dataBnPerp.data && dataBnPerp.data.symolPrices) {
      BINANCE.PERP = dataBnPerp.data.symolPrices;
    }
  });
  DatafeedApiService.lastPriceIn24hr('binance', 'spot', '', function (dataBnSpot) {
    if (dataBnSpot && dataBnSpot.data && dataBnSpot.data.symolPrices) {
      BINANCE.SPOT = dataBnSpot.data.symolPrices;
    }
  });
}

function loadSymbolModel() {
  SYMBOL_MODEL_LOAD_LIST.forEach((obj) => {
    GeneralApiService.getAllSymbolModel(obj.platform, obj.symbolType, function (res) {
      for (const resKey in res) {
        SYMBOL_MODEL_MAP[obj.platform + '-' + obj.symbolType + '-' + resKey] = res[resKey];
      }
    });
    GeneralApiService.getAllPrice(obj.platform, obj.symbolType, function (res) {
      for (const resKey in res) {
        PLATFORM_SYMBOL_TYPE_PRICE_MAP[obj.platform + '-' + obj.symbolType + '-' + resKey] = res[resKey];
      }
    });
  });
}

function getPerpSymbolName(coinName, exchange) {
  if (Object.keys(OKX).length > 0 && Object.keys(BINANCE.PERP).length > 0 && Object.keys(BYBIT.PERP).length > 0) {
    if (exchange.toUpperCase() === 'BINANCE') {
      if (BINANCE.PERP[coinName + 'USDT']) {
        return coinName + 'USDT';
      }
      if (BINANCE.PERP[coinName + 'BUSD']) {
        return coinName + 'BUSD';
      }
    } else if (exchange.toUpperCase() === 'FTX') {
      return coinName + '-PERP';
    } else if (exchange.toUpperCase() === 'BYBIT') {
      return coinName + 'USDT';
    } else if (exchange.toUpperCase() === 'OKX' || exchange.toUpperCase() === 'OKEX') {
      return coinName + '-USDT-SWAP';
    }
  } else {
    loadData();
    Message.warning('Please retry');
  }
}

function getPerpSymbolPrice(symbol, exchange) {
  if (Object.keys(OKX).length > 0 && Object.keys(BINANCE.PERP).length > 0 && Object.keys(BYBIT.PERP).length > 0) {
    try {
      if (exchange.toUpperCase() === 'BINANCE') {
        if (BINANCE.PERP[symbol]) {
          return BINANCE.PERP[symbol].lastPrice;
        }
      } else if (exchange.toUpperCase() === 'BYBIT') {
        if (BYBIT.PERP[symbol]) {
          return BYBIT.PERP[symbol].lastPrice;
        }
      } else if (exchange.toUpperCase() === 'OKX' || exchange.toUpperCase() === 'OKEX') {
        return OKX[symbol].lastPrice;
      }
    } catch (e) {
      console.log(symbol);
      console.error(e);
    }
  } else {
    loadData();
    Message.warning('Please retry');
  }
}

function getSymbolModel(platform, symbolType, symbol) {
  if (platform === GeneralApiService.PLATFORM.FTX) {
    symbolType = 'ALL';
  } else if (platform === GeneralApiService.PLATFORM.DERIBIT) {
    symbolType = 'ALL';
  } else if (platform === GeneralApiService.PLATFORM.OKX) {
    symbolType = 'ALL';
  }
  return SYMBOL_MODEL_MAP[platform + '-' + symbolType + '-' + symbol];
}

function getSymbolPrice(platform, symbolType, symbol) {
  if (['USDTUSDT', 'BUSDUSDT', 'USDCUSDT', 'BUSDBUSD', 'USDTBUSD', 'USDCBUSD', 'USDCUSDC', 'BUSDUSDC', 'USDTUSDC'].indexOf(symbol) > -1) {
    return 1;
  }
  if (platform === GeneralApiService.PLATFORM.FTX) {
    symbolType = 'ALL';
  } else if (platform === GeneralApiService.PLATFORM.DERIBIT) {
    symbolType = 'ALL';
  } else if (platform === GeneralApiService.PLATFORM.OKX) {
    symbolType = 'ALL';
  }
  return PLATFORM_SYMBOL_TYPE_PRICE_MAP[platform + '-' + symbolType + '-' + symbol];
}

function getSymbolName(platform, symbolType, coin) {
  return CoinToPerpNameResolver.nameResolver(platform, symbolType, coin);
}

function getSymbolList(platform, symbolType) {
  let symbolList = [];
  (Object.keys(PLATFORM_SYMBOL_TYPE_PRICE_MAP)).forEach((key) => {
    if (platform === GeneralApiService.PLATFORM.FTX) {
      if (key.startsWith(platform + '-ALL')) {
        let symbol = key.replaceAll(platform + '-ALL-', '');
        if (symbolType === GeneralApiService.SYMBOL_TYPE.SPOT) {
          if (symbol.indexOf('/') > -1) {
            symbolList.push(symbol);
          }
        } else if (symbolType === GeneralApiService.SYMBOL_TYPE.ALL){
          symbolList.push(symbol);
        } else {
          if (symbol.indexOf('/') === -1) {
            symbolList.push(symbol);
          }
        }
      }
    } else if (platform === GeneralApiService.PLATFORM.DERIBIT) {
      if (key.startsWith(platform + '-ALL')) {
        let symbol = key.replaceAll(platform + '-ALL-', '');
        if (symbolType === GeneralApiService.SYMBOL_TYPE.OPTIONS) {
          if (symbol.endsWith('-C') || symbol.endsWith('-P')) {
            symbolList.push(symbol);
          }
        } else if (symbolType === GeneralApiService.SYMBOL_TYPE.ALL){
          symbolList.push(symbol);
        } else {
          if (!(symbol.endsWith('-C') || symbol.endsWith('-P'))) {
            symbolList.push(symbol);
          }
        }
      }
    } else if (platform === GeneralApiService.PLATFORM.OKX) {
      if (key.startsWith(platform + '-ALL')) {
        let symbol = key.replaceAll(platform + '-ALL-', '');
        if (symbolType === GeneralApiService.SYMBOL_TYPE.SPOT) {
          if (symbol.endsWith("-USDT") || symbol.endsWith("-USDC") || symbol.endsWith("-USDK") || symbol.endsWith("-DAI") || symbol.endsWith("-EURT")) {
            symbolList.push(symbol);
          }
        } else if (symbolType === GeneralApiService.SYMBOL_TYPE.ALL){
          symbolList.push(symbol);
        } else {
          if (!(symbol.endsWith("-USDT") || symbol.endsWith("-USDC") || symbol.endsWith("-USDK") || symbol.endsWith("-DAI") || symbol.endsWith("-EURT"))) {
            symbolList.push(symbol);
          }
        }
      }
    } else {
      if (key.startsWith(platform + '-' + symbolType)) {
        symbolList.push(key.replaceAll(platform + '-' + symbolType + '-', ''));
      }
    }
  });
  return symbolList;
}

function init() {
  loadData();
  setInterval(loadData, 60000);
}

export default {
  init,
  getSymbolModel,
  getSymbolPrice,
  getSymbolName,
  getPerpSymbolName,
  getPerpSymbolPrice,
  getSymbolList
}
</script>
