<script type="text/javascript">
import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper"

function saveArticle(article, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.saveArticle, article, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function loadArticle(titleKey, language, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.loadArticle + "?fetchMeta=true&titleKey=" + titleKey + "&language=" + language, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function deleteArticle(titleKey, language, success, error) {
  AbmNet.deleteRequest(ServiceMapper.WEB_SERVICE.deleteArticle + "?language=" + language + "&titleKey=" + titleKey, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function getArticleList(labels, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.getArticleList + "?ignoreContent=true&labelsSelectors=" + labels,
      function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function uploadImage(formData, success, error) {
  AbmNet.postFormData(ServiceMapper.WEB_SERVICE.uploadImage, formData, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

export default {
  saveArticle,
  loadArticle,
  getArticleList,
  deleteArticle,
  uploadImage
}
</script>