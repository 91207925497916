import { render, staticRenderFns } from "./Lock.vue?vue&type=template&id=0cd6531c&scoped=true"
import script from "./Lock.vue?vue&type=script&lang=js"
export * from "./Lock.vue?vue&type=script&lang=js"
import style0 from "./Lock.vue?vue&type=style&index=0&id=0cd6531c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd6531c",
  null
  
)

export default component.exports