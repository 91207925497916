<script lang="javascript">
import param from "@/paras/Paras";

const trans = {
  lang: '繁體中文',
  copyright: 'Copyright © 2022 ' + param.getCompanyDisplayValue(),
  lang_china: '繁體中文',
  lang_english: 'English',
  lang_japan: '日本',
  lang_korea: '한국인',
  lang_thailand: 'ไทย',
  changePassword: '修改密碼',
  manageAccount: '賬號管理',
  pleaseLoginFirst: '請先登錄',
  operationSuccess: '操作成功',
  operationFail: '操作失敗',
  confirm: '確認',
  action: "操作",
  loading: '加載中...',
  customerPanel: {
    initInfo: '請不要把API信息發給任何人，包括我們的工作人員！',
    subtitle: '客戶機密信息收錄系統，此頁面有效期僅30分鐘',
    delA1: '刪除第 ',
    delA2: '賬號',
    st: ' 個',
    nd: ' 個',
    rd: ' 個',
    th: ' 個',
    addA1: '添加第 ',
    addA2: '賬號',
    preCheck: '校驗API',
    submit: '提交'
  },
  week: {
    Sun: 'Sun',
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Web',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat'
  },
  login: {
    login: '登錄',
    processing: '處理中...',
    subTitle: '統一認證管理平台',
    logout: '登出',
    register: '註冊',
    email: '郵箱',
    username: '用戶名',
    password: '密碼',
    forgetPwd: '忘記密碼',
    changePwd: '設置',
    start: '登入',
    dontHaveAccount: '我還沒有賬號',
    loginSuccess: '您已成功登錄',
    logoutSuccess: '您已登出當前賬戶',
    loginTitle: '登錄通知'
  },
  lock: {
    description: '由於安全策略，網頁已被鎖定。',
    unlock: '解鎖',
    lock: '鎖定'
  },
  userInfo: {
    alias: '暱稱',
    status: '狀態',
    role: '角色',
    userInfoSetting: '用戶信息配置',
    email: '郵箱',
    username: '登錄名',
    password: '密碼',
    newPassword: '新密碼',
    verification: '驗證碼',
    send: '發送',
    update: '更新'
  },
  navBars: {
    navigation: '導航',
    controlPanel: '控制面板',
    accountMgnt: '賬號管理',
    analysisMgnt: '分析',
    riskMgnt: '風險管理',
    strategyMgnt: '策略管理中心',
    tradeMgnt: '聚合交易中心',
    testMgnt: '測試中心',
    web3Mgnt: 'WEB 3',
    userPermissionManagement: '用戶權限管理',
    traCupPanel: 'Tracup',
    channelMgnt: '客戶關係',
    wallet: '錢包',
    cms: 'CMS'
  },
  mobileNav: {
    controlPanel: '首頁',
    accountMgnt: '賬號管理',
    news: '快訊',
    userConfig: '設置'
  },
  permission: {
    SUPER_ADMIN: '超級管理員',
    ADMIN: '管理員',
    USER: '普通用戶',
    CONTROL_CENTER: '中台管理員',
    PLATFORM_ADMIN: '中台管理員',
    PLATFORM_USER: '中台用戶',
    DEMO_USER: '展示用戶',
    RECEPTIONIST: '市場部門',
    INSTANCE_OPERATOR: '賬號管理員',
    CUSTOMER_ROLE_001: '自定義用戶1',
    CUSTOMER_ROLE_002: '自定義用戶2',
    CUSTOMER_ROLE_003: '自定義用戶3',
    ChangeUserRole: "修改角色",
    readFlag: '讀',
    writeFlag: '寫',
    deleteFlag: '刪除',
    userList: '用戶列表',
    roleInstanceList: '實例權限列表'
  },
  controlPanelHome: {
    lastLoginTime: ', 上次登錄時間： ',
    allAccountOperation: '所有人的活動',
    queryAll: '查看全部',
    tradeTimes: '累計成交次數',
    tradeAmounts: '累計成交額',
    tradeAmount30: '近30日成交額',
    errorPosition: '錯誤倉位提醒',
    message: '私信提醒',
    groupMessage: '群組消息提醒',
    system7Vol: '成交量',
    unreadMsg: '私信',
    unreadGroupMsg: '群消息',
    taskConfig: '任務配置',
    pendingTask: '未處理的任務'
  },
  tradeMgnt: {
    load: '載入',
    kPanel: {
      open: '開',
      close: '收',
      high: '高',
      low: '低',
      change: '漲跌幅',
      amplitude: '振幅',
      vol: '成交量'
    },
    infoPanel: {
      change24: '24h漲跌',
      low24: '24h最低價',
      high24: '24h最高價',
      vol24: '24h成交量',
      volU24: '24h成交額'
    },
    klineType: {
      m1: '1分鐘',
      m5: '5分鐘',
      m15: '15分鐘',
      m30: '30分鐘',
      h1: '1小時',
      h2: '2小時',
      h4: '4小時',
      d1: '1天',
      week: '週線'
    }
  },
  cms: {
    title: "语言标题",
    titleKey: "唯一标题",
    desc: "文章简介",
    content: "内容",
    author: "发布人",
    cover: "封面",
    labels: "标签",
    labelsPlaceholder: "请输入标签，用英文逗号分隔",
    articleListTitle: "文章列表",
    publish: "发布",
    draft: "草稿",
    save: "保存",
    language: "语言",
    cancel: "取消",
    edit: "编辑",
    operation: "操作",
    articleManagement: "文章管理",
    articleCover: "文章封面",
    jobName: '工作岗位名称',
    jobLocation: '工作岗位location',
    delete: 'Delete', // or '删除' for Chinese
    // Add more translations as needed
  },
  accountMgnt: {
    assetInfo: '資產信息',
    total: '總淨值',
    profit: '總盈利',
    tradeStatus: '交易狀態',
    manageInstance: '託管實例',
    news: '最新消息',
    addAccount: '添加賬戶',
    linkAccount: '關聯賬戶',
    accountName: '賬號名',
    originBalance: '初始資金',
    currentBalance: '當前資金',
    add: '添加',
    rename: '重命名',
    reset: '重置賬號',
    cancelLink: '取消關聯',
    deleteAccount: '刪除賬號',
    bindCustomer: '绑定客户',
    nickName: '賬號暱稱',
    createInstance: '添加賬號實例',
    exchange: '交易所',
    subAccountName: '子賬號名',
    userInfo: '用戶信息'
  },
  instanceStatics: {
    AssetDollarRatio: '資產美元占比',
    accountStatistics: '賬號統計',
    exchangeDollarAssets: '交易所美元資產',
    exchange: '交易所',
    count: '數量',
    total: '總計'
  },
  customerProfit: {
    totalEarning: '收益統計',
    accounts: '個賬戶'
  },
  riskFreeProfit: {
    RiskFreeRateOfReturn: '市場無風險利率',
    FromBinanceDemandDepositRates: '數據來自幣安活期收益',
    APR: 'APR'
  },
  downloadPage: {
    HowToDownloadTheAppForIOS: '如何下載 IOS 的 App',
    step0: 'App 無需安裝任何證書，從官網即可直接下載。一次安裝，永久自動更新',
    step1: '使用 iPhone 自帶的 Safari（必須是Safari） 瀏覽器打開網址：',
    step1_1: ' 然後點擊工具欄中的分享按鈕。',
    step2: '點擊添加到主屏幕。',
    step3: '等待約 1~2 秒 logo 加載完成後，點擊添加按鈕。',
    step4: '您將看到 Oneport 的 App 已經安裝到您的桌面。'
  }
}

export default {
  trans
}

</script>
