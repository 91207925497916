let SERVER = process.env.VUE_APP_TRIALPHA_PROD;

if (window.location.hostname === 'localhost') {
  SERVER = 'http://localhost:5000';
} else if (window.location.hostname === 'internal.oneport.ai') {
  SERVER = 'http://internal.oneport.ai';
} else {
  SERVER = 'https://' + window.location.hostname;
}

function getCompanyValue() {
  return process.env.VUE_APP_COMPANY_NAME;
}

function getCompanyDisplayValue() {
  return 'Oneport';
}

export default {
  SERVER,
  getCompanyDisplayValue,
  getCompanyValue
}