<template>
  <div>
    <el-row class="hidden-md-and-up" style="width: 100%;position: fixed;margin-top: 25px;z-index: 10">
      <el-card class="black-card"
               style="width: 100%;height: 178px;border-radius: 0 0 25px 25px;background-color: #343a40;border-top: 0;position: fixed;z-index: 999;box-shadow: 0 3px 10px #343a40"
               body-style="padding: 8px" shadow="none">
        <el-row style="margin-top: 5px">
          <el-col :span="12" style="padding-top: 15px">
            <span class="dark-mode-font" style="color: white;display: inline-block;float: left;margin-left: 15px">{{
                $i('accountMgnt.total')
              }}</span>
          </el-col>
          <el-col :span="12" style="padding-top: 15px">
            <el-select class="select-basic-coin" v-model="balanceRes.coin" placeholder="请选择" size="mini"
                       style="width: 75px;float: right;margin-right: 15px" @change="changeCoin">
              <el-option
                  v-for="item in basicCoinList"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="24" style="padding-top: 0">
            <span class="dark-mode-font" style="color: white;display: inline-block;float: left;margin-left: 15px">{{
                Number.prototype.toFormatNum(Number(balanceRes.currentBalance.toFixed(4)))
              }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="12" style="padding-top: 5px">
            <span class="dark-mode-font" style="color: white;display: inline-block;float: left;margin-left: 15px">{{
                $i('accountMgnt.profit')
              }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="18" style="padding-top: 0">
              <span class="dark-mode-font"
                    :style="'display: inline-block;float: left;margin-left: 15px;' + (balanceRes.currentProfit > 0 ? 'color: #0ecb81' : 'color: #f6465d')">{{
                  Number.prototype.toFormatNum(Number(balanceRes.currentProfit.toFixed(4)))
                }}</span>
          </el-col>
          <el-col :span="6" style="padding-top: 0">
              <span class="dark-mode-font"
                    :style="'display: inline-block;float: right;margin-right: 15px;' + (balanceRes.currentProfit > 0 ? 'color: #0ecb81' : 'color: #f6465d')">{{
                  (balanceRes.currentProfit / balanceRes.originBalance * 100).toFixed(3)
                }}%</span>
          </el-col>
        </el-row>
      </el-card>
    </el-row>
    <div class="account-full-panel">
      <div style="padding: 0 6.18% 100px 6.18%;margin: 68px auto auto auto;max-width: 2068px">
        <el-row class="hidden-sm-and-down" style="width: 100%;display: flex;">
          <el-card class="black-card" style="width: 268px;height: 268px" body-style="padding: 10px">
            <el-row>
              <span class="dark-mode-font" style="float: left;font-size: 13px;margin-left: 5px;margin-top: 5px"><b>{{
                  $i('accountMgnt.assetInfo')
                }}</b></span>
              <el-select class="select-basic-coin" v-model="balanceRes.coin" placeholder="请选择" size="mini"
                         style="width: 76px;float: right;margin-right: 0" @change="changeCoin">
                <el-option
                    v-for="item in basicCoinList"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </el-row>
            <el-progress type="circle"
                         :percentage="Math.min(100, Math.max(0, balanceRes.currentProfit / balanceRes.originBalance * 100))"
                         v-if="balanceRes.originBalance > 0" status="success" :width="210" :show-text="false"
                         :stroke-width="13" color="#0ecb81"
                         style="position: absolute;margin-left: -105px;margin-top: 0"></el-progress>
            <el-row style="margin-top: 32px">
              <span class="dark-mode-font"
                    style="color: white;font-size: 14px;font-weight: 600">{{ $i('accountMgnt.total') }}</span>
            </el-row>
            <el-row style="margin-top: 5px">
              <span class="dark-mode-font" style="color: white;font-size: 20px"><b>{{
                  Number.prototype.toFormatNum(balanceRes.currentBalance.toFixed(3))
                }}</b></span>
            </el-row>
            <el-row style="margin-top: 16px">
              <span class="dark-mode-font" style="color: white;font-size: 14px"><b>{{
                  $i('accountMgnt.profit')
                }}</b></span>
            </el-row>
            <el-row style="margin-top: 0">
              <span class="dark-mode-font" style="color: white;font-size: 20px"><b>{{
                  Number.prototype.toFormatNum(balanceRes.currentProfit.toFixed(3))
                }}</b></span>
            </el-row>
            <el-row style="margin-top: 0">
              <span class="dark-mode-font"
                    :style="'' + (balanceRes.currentProfit > 0 ? 'color: #0ecb81' : 'color: #f6465d') + ';font-size: 14px'"><b>{{
                  (balanceRes.currentProfit / balanceRes.originBalance * 100).toFixed(2)
                }}%</b></span>
            </el-row>
            <el-row class="hidden-sm-and-down" style="margin-top: 19px;display: flex">
              <span class="dark-mode-font"
                    style="display: inline-block;float: left;font-size: 12px;margin-left: 190px;margin-top: -5px">{{
                  $i('accountMgnt.profit')
                }}</span>
              <div style="width: 10px;height: 10px;background-color: #0ecb81;margin-left: 5px"></div>
            </el-row>
          </el-card>
          <el-card class="black-card" style="width: 368px;height: 268px;margin-left: 20px" body-style="padding: 0">
            <el-row>
              <el-card class="black-card" body-style="padding:5px"
                       style="border-radius: 3px 3px 0 0;height: 35px;background-color: #32383e;border: 0">
                <span class="dark-mode-font"
                      style="color: #FFFFFF;overflow-y: auto;display: inline-block;float: left;margin-left: 10px;font-size: 14px;margin-top: 1px">Market news</span>
                <span class="dark-mode-font"
                      style=";overflow-y: auto;display: inline-block;float: right;margin-right: 10px;font-size: 13px;margin-top: 2px">{{
                    timeInfo
                  }}</span>
              </el-card>
            </el-row>
            <el-row>
              <el-card class="black-card"
                       style="width: 368px;height: 233px;overflow-y: auto;border: 0;border-radius: 0 0 3px 3px"
                       body-style="padding: 0">
                <newest style="width: 100%;height: 100%"></newest>
              </el-card>
            </el-row>
          </el-card>
          <div style="flex: 1;margin-left: 20px">
            <exchange-info :exchanges-count-map="exchangesCount" style="width: 100%;height: 100%"/>
          </div>
        </el-row>
        <el-divider class="mgnt-divider"><i class="el-icon-s-platform" style="color: #444c56"></i></el-divider>
        <el-row id="filter_row" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static">
          <el-col :span="20" style="display: flex">
            <el-button class="dark-mode-btn hidden-sm-and-down"
                       style=";float: left;height: 35px;padding: 5px 18px 5px 18px;font-size: 12px;border-radius: 3px 0 0 3px;border-right: 0"
                       @click="openCreateAccountDrawer" v-auth:addAccount>{{
                $i('accountMgnt.addAccount')
              }}
            </el-button>
            <el-popover
                popper-class="black-card"
                placement="top"
                width="468"
                style=""
                v-model="linkAccountVisible">
              <el-row style="padding: 5px 10px 5px 10px">
                <el-col :span="24" style="display: flex">
                  <el-input placeholder="Link ID" v-model="linkInsId" class="searchAccount-mobile search-m"
                            style="width: 328px;margin-left: 15px"></el-input>
                  <el-button class="dark-mode-btn"
                             style="float: left;height: 35px;padding: 5px 18px 5px 18px;font-size: 12px;margin-left: 0;border-radius: 0 3px 3px 0"
                             @click="linkAccount">{{ $i('accountMgnt.add') }}
                  </el-button>
                </el-col>
              </el-row>
              <el-button class="dark-mode-btn hidden-sm-and-down"
                         style="float: left;height: 35px;padding: 5px 18px 5px 18px;font-size: 12px;margin-left: 0;border-radius: 0;border-right: 0"
                         slot="reference">{{ $i('accountMgnt.linkAccount') }}
              </el-button>
            </el-popover>
            <el-select class="select-exchange" v-model="selectExchange" placeholder="请选择" size="mini"
                       style="height: 35px;float: left;margin-right: 0;border-right: 0" @change="render(true)">
              <el-option
                  v-for="item in supportExchanges"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
            <el-select v-auth:strategyFilter class="select-exchange" v-model="selectStrategy" placeholder="请选择"
                       size="mini" style="height: 35px;float: left;margin-right: 0" @change="render(true)">
              <el-option
                  v-for="item in supportStrategies"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
            <el-input :placeholder="$i('accountMgnt.accountName')" v-model="searchAccount"
                      class="searchAccount search-pc"></el-input>
            <el-button class="dark-mode-btn"
                       style="float: left;height: 35px;padding: 5px 18px 5px 18px;font-size: 12px;margin-left: 0;border-radius: 0 3px 3px 0"
                       @click="render(true)"><i class="el-icon-s-promotion" style="font-size: 15px"></i></el-button>
          </el-col>
          <el-col :span="4" class="hidden-sm-and-down">
            <el-button class="dark-mode-btn"
                       style="float: right;height: 35px;padding: 5px 18px 5px 18px;font-size: 12px;margin-right: 0;border-radius: 0 3px 3px 0"
                       @click="render(false)"><i class="el-icon-refresh" style="font-size: 15px"></i></el-button>
            <el-dropdown style="float: right" @command="changeRefresh">
              <el-button class="dark-mode-btn"
                         style="height: 35px;padding: 5px 15px 5px 15px;border-radius: 3px 0 0 3px;border-right: 0;font-size: 12px">
                {{ refreshGap / 60 }} MIN<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown" size="small">
                <el-dropdown-item v-for="item in refreshTime"
                                  :key="item.name"
                                  :label="item.name"
                                  :value="item.value"
                                  :command="item.value">{{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
        <el-row class="hidden-md-and-up" style="margin-top: 10px;width: 100%">
          <el-col :span="7" style="display: flex">
            <el-select class="select-exchange-small mobile-e" v-model="selectExchange" placeholder="请选择" size="mini"
                       style="height: 35px;width: 100%;float: left;border-radius: 3px 0 0 3px;margin-right: 0"
                       @change="render(true)">
              <el-option
                  v-for="item in supportExchanges"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="7" style="display: flex">
            <el-select v-auth:strategyFilter class="select-exchange-small mobile-s" v-model="selectStrategy"
                       placeholder="请选择" size="mini"
                       style="height: 35px;width: 100%;float: left;border-radius: 0;margin-right: 0"
                       @change="render(true)">
              <el-option
                  v-for="item in supportStrategies"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-col>
          <el-col v-auth:strategyFilter :span="10" style="display: flex">
            <el-input :placeholder="$i('accountMgnt.accountName')" v-model="searchAccount"
                      class="searchAccount-mobile search-m"></el-input>
            <el-button class="dark-mode-btn"
                       style="float: left;height: 35px;padding: 5px 18px 5px 18px;font-size: 12px;margin-left: 0;border-radius: 0 3px 3px 0"
                       @click="render(true)"><i class="el-icon-s-promotion" style="font-size: 15px"></i></el-button>
          </el-col>
          <el-col v-if="!hasPermission('strategyFilter')" :span="17" style="display: flex">
            <el-input :placeholder="$i('accountMgnt.accountName')" v-model="searchAccount"
                      class="searchAccount-mobile search-m"></el-input>
            <el-button class="dark-mode-btn"
                       style="float: left;height: 35px;padding: 5px 18px 5px 18px;font-size: 12px;margin-left: 0;border-radius: 0 3px 3px 0"
                       @click="render(true)"><i class="el-icon-s-promotion" style="font-size: 15px"></i></el-button>
          </el-col>
        </el-row>
        <el-row class="account-panel-out">
          <el-skeleton animated :loading="loadingInstance" :throttle="268">
            <template slot="template">
              <trialpha-sk
                  :style="'margin-top: 20px;width: 60%;float: left'"></trialpha-sk>
              <trialpha-sk
                  :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
              <trialpha-sk
                  :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
              <trialpha-sk
                  :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
              <trialpha-sk
                  :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
              <trialpha-sk
                  :style="'margin-top: 20px;width: 100%;float: left'"></trialpha-sk>
              <trialpha-sk
                  :style="'margin-top: 40px;width: 60%;float: left'"></trialpha-sk>
              <trialpha-sk
                  :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
              <trialpha-sk
                  :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
              <trialpha-sk
                  :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
              <trialpha-sk
                  :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
              <trialpha-sk
                  :style="'margin-top: 20px;width: 100%;float: left'"></trialpha-sk>
            </template>
            <template>
              <div v-infinite-scroll="renderCurrentInstance"
                   :infinite-scroll-disabled="instanceListRender.length >= instanceList.length">
                <div v-for="(ins, index) in instanceListRender" :key="ins.instanceId"
                     class="black-card account-panel-inner"
                     style="border: 0">
                  <el-card
                      :style="'z-index:90;' + (index === 0 ? 'width: 218px;height: 100%;background-color: #2b323b;border-radius: 0;border-right: 0;' : 'width: 218px;height: 100%;background-color: #2b323b;border-radius: 0;border-radius: 0;border-right: 0;') + ''"
                      body-style="padding: 0"
                      class="black-card hidden-sm-and-down">
                    <el-row style="margin-top: 46px">
                      <el-tooltip :content="'Link ID: ' + ins.instanceId + ':' + ins.name + ''" class="item" :open-delay="666"
                                  effect="dark"
                                  placement="top">
                      <span class="dark-mode-font ellipsis-line-strategy-ins-name"
                            style="display: inline-block;color: white;font-size: 18px;font-family: DIN-Bold,serif"><b>{{
                          ins.name
                        }}</b></span>
                      </el-tooltip>
                    </el-row>
                    <el-row style="margin-top: 35px">
                      <el-col :span="12">
                        <el-dropdown style="width: 100%" trigger="click" @command="moreAction">
                          <el-button class="dark-mode-btn"
                                     style="height: 20px;padding: 3px 18px 3px 18px;font-size: 12px;border-radius: 0;border-left: 0;border-bottom: 0;width: 100%;z-index: 999;">
                            <i class="el-icon-more"></i>
                          </el-button>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="{action: 'forceRefresh', id: ins.instanceId, index: index}"
                                              v-auth:renameAccount>强制刷新
                            </el-dropdown-item>
                            <el-dropdown-item
                                :command="{action: 'rename', id: ins.instanceId, index: index, name: ins.name}"
                                v-auth:renameAccount>{{ $i('accountMgnt.rename') }}
                            </el-dropdown-item>
                            <el-dropdown-item :command="{action: 'reset', id: ins.instanceId, index: index}"
                                              v-auth:resetAccount>{{ $i('accountMgnt.reset') }}
                            </el-dropdown-item>
                            <el-dropdown-item :command="{action: 'unlink', id: ins.instanceId, index: index}"
                                              v-auth:unlinkAccount>{{ $i('accountMgnt.cancelLink') }}
                            </el-dropdown-item>
                            <el-dropdown-item :command="{action: 'delete', id: ins.instanceId, index: index}"
                                              v-auth:deleteAccount>{{ $i('accountMgnt.deleteAccount') }}
                            </el-dropdown-item>
                            <el-dropdown-item :command="{action: 'bindCustomer', ins: ins, index: index}" v-auth:bindCMS>
                              {{
                                ins.customerInfo != null && ins.customerInfo.name != null ? ins.customerInfo.name : $i('accountMgnt.bindCustomer')
                              }}
                            </el-dropdown-item>
                            <el-dropdown-item :command="{action: 'settlementPage', ins: ins, index: index}"
                            v-auth:settlementPage>{{ 'Settlement' }}
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </el-col>
                      <el-col :span="12">
                        <el-button class="dark-mode-btn"
                                   style="height: 20px;padding: 3px 18px 3px 18px;font-size: 12px;border-radius: 0;border-right: 0;border-left: 0;border-bottom: 0;width: 100%;z-index: 999;"
                                   @click="openAccountConfig(ins)"
                                   v-auth:configAccount><i
                            class="el-icon-s-tools"></i></el-button>
                      </el-col>
                    </el-row>
                  </el-card>
                  <el-card class="black-card" shadow="always" style="border-radius: 0;flex: 1" body-style="padding: 0">
                    <el-card body-style="padding: 0" class="black-card hidden-sm-and-down"
                             shadow="none"
                             style="z-index: 2;width: 398px;height: 25px;margin: 0 auto auto;border-top: 0;border-radius: 0;display: flex;box-shadow: 0 0 15px #444c56;">
                      <el-row style="width: 398px;margin-top: -1px;z-index: 2;">
                        <el-col class="hidden-sm-and-down" :span="12">
                          <el-row>
                            <el-col :span="10">
                            <span class="dark-mode-font" style="color: white;font-size: 13px;"><b>{{
                                $i('accountMgnt.originBalance')
                              }}</b></span>
                            </el-col>
                            <el-col :span="14">
                            <span class="dark-mode-font"
                                  style="color: white;font-size: 13px;"><b>{{ ins.originBalance.toFixed(6) }}</b></span>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col class="hidden-sm-and-down" :span="12" style="border-left: 1px #444c56 solid">
                          <el-row>
                            <el-col :span="10">
                            <span class="dark-mode-font" style="color: white;font-size: 13px;"><b>{{
                                $i('accountMgnt.currentBalance')
                              }}</b></span>
                            </el-col>
                            <el-col :span="14">
                            <span class="dark-mode-font"
                                  :style="'font-size:13px;' + (ins.currentBalance > ins.originBalance ? 'color: #0ecb81' : 'color: #f6465d') + ''"><b>{{
                                ins.currentBalance.toFixed(6)
                              }}</b></span>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-card>
                    <el-card body-style="padding: 0" class="black-card hidden-md-and-up"
                             shadow="none"
                             style="z-index: 2;width: calc(100% + 2px);height: 25px;margin: 0 0 0 -2px;border-top: 0;border-right: 0;border-radius: 0;box-shadow: 0 0 15px #444c56">
                      <el-row style="margin-top: -1px;z-index: 2;border-right: 0">
                        <el-col :span="9">
                          <el-row style="width: 100%">
                            <el-col :span="24">
                            <span class="dark-mode-font ellipsis-line"
                                  style="color: white;font-size: 12px;"><b>{{ ins.originBalance.toFixed(4) }}</b></span>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="9" style="border-left: 1px #444c56 solid;border-right: 1px #444c56 solid">
                          <el-row>
                            <el-col :span="24">
                            <span class="dark-mode-font"
                                  :style="'font-size:12px;' + (ins.currentBalance > ins.originBalance ? 'color: #0ecb81' : 'color: #f6465d') + ''"><b>{{
                                ins.currentBalance.toFixed(4)
                              }}</b></span>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="6" style="border-right: 0">
                          <el-row style="width: 100%;border-right: 0">
                            <el-col :span="24" style="border-right: 0">
                          <span class="dark-mode-font"
                                :style="'font-size: 12px;border-right: 0;' + (ins.currentBalance > ins.originBalance ? 'color: #0ecb81' : 'color: #f6465d') + ''"><b>{{
                              ((ins.currentBalance - ins.originBalance) / ins.originBalance * 100).toFixed(2)
                            }}%</b></span>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-card>
                    <div v-auth:showStrategyDescription
                         style="z-index: 2;background-color: rgba(0, 0, 0, 0);width: 210px;height: 25px;margin-left: -2px;margin-top: 90px;border-top: 0;border-radius: 0;position: absolute;">
                      <el-row style="margin-top: -1px;z-index: 3;">
                        <el-col :span="24" style="padding-left: 10px">
                        <span class="dark-mode-font ellipsis-line-strategy"
                              style=";color: white;font-size: 12px;display: inline-block;float: left">{{
                            getStrategyListString(ins.strategyInstances)
                          }}</span>
                        </el-col>
                      </el-row>
                    </div>
                    <account-profit-panel :id="'account_' + ins.instanceId" :ref="'profit-panel-' + ins.instanceId + ''"
                                          :instance="ins"
                                          style="width: 100%;height: 110px;margin-top: 1px;z-index: 1;margin-left: 1px"></account-profit-panel>
                    <el-card body-style="padding: 0"
                             class="black-card hidden-md-and-up"
                             style="position: absolute;width: 100%;margin-left: -1px;border-radius: 0;margin-top: -4px;border: 0">
                      <el-row style="margin-top: 0">
                        <el-col :span="8">
                          <el-dropdown style="width: 100%" trigger="click" @command="moreAction">
                            <el-button class="dark-mode-btn"
                                       style="height: 20px;padding: 3px 18px 3px 18px;font-size: 12px;border-radius: 0;width: 100%;z-index: 999;">
                              <i class="el-icon-more"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                  :command="{action: 'rename', id: ins.instanceId, index: index, name: ins.name}"
                                  v-auth:renameAccount>{{ $i('accountMgnt.rename') }}
                              </el-dropdown-item>
                              <el-dropdown-item :command="{action: 'reset', id: ins.instanceId, index: index}"
                                                v-auth:resetAccount>{{ $i('accountMgnt.reset') }}
                              </el-dropdown-item>
                              <el-dropdown-item :command="{action: 'unlink', id: ins.instanceId, index: index}"
                                                v-auth:unlinkAccount>{{ $i('accountMgnt.cancelLink') }}
                              </el-dropdown-item>
                              <el-dropdown-item :command="{action: 'delete', id: ins.instanceId, index: index}"
                                                v-auth:deleteAccount>{{ $i('accountMgnt.deleteAccount') }}
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>

                        </el-col>
                        <el-col :span="8">
                          <el-button class="dark-mode-btn"
                                     style="height: 20px;padding: 3px 18px 3px 18px;font-size: 12px;border-radius: 0;border-left: 0;width: 100%;z-index: 999;"
                                     @click="openPositionConfig(ins)"
                                     v-auth:configAccount><i
                              class="el-icon-s-finance"></i></el-button>
                        </el-col>
                        <el-col :span="8">
                          <el-button class="dark-mode-btn"
                                     style="height: 20px;padding: 3px 18px 3px 18px;font-size: 12px;border-radius: 0;border-left: 0;width: 100%;z-index: 999;"
                                     @click="openAccountConfig(ins)"
                                     v-auth:configAccount><i
                              class="el-icon-s-tools"></i></el-button>
                        </el-col>
                      </el-row>
                    </el-card>
                    <el-card class="black-card hidden-md-and-up" shadow="none"
                             style="z-index: 2;width: 100%;height: 25px;position: absolute;margin: -159px 0 2px -1px;border-radius: 0"
                             body-style="padding: 0 0 5px 0">
                      <el-row style="margin-top: -2px">
                      <span class="dark-mode-font"
                            style="color: white;font-size: 12px;display: inline-block;margin-top: 0">{{
                          ins.name
                        }}</span>
                      </el-row>
                    </el-card>
                    <el-tooltip class="item" effect="dark" placement="top">
                      <div slot="content">
                        <el-row v-for="item in getError(ins)" :key="item"><span>{{ item }}</span></el-row>
                      </div>
                      <el-card v-auth:showError class="black-card shine_red hidden-sm-and-down" v-if="hasError(ins)"
                               body-style="padding: 0"
                               style="cursor: pointer;width: 10px;height: 10px;position: absolute;margin-top: -126px;margin-left: calc(100% - 458px);border-radius: 50px;background-color: #e93a5b;border: 0;box-shadow: 0 0 15px #ff6c6c"></el-card>
                    </el-tooltip>
                  </el-card>
                  <el-card class="black-card hidden-sm-and-down" shadow="hover"
                           style="border-radius: 0;width: 218px;border-left: 0;background-color: #2b323b;z-index: 2">
                    <el-row>
                      <el-popover
                          v-auth:accountPlan
                          @show="renderAccountPlan(ins)"
                          placement="right"
                          width="798"
                          trigger="click">
                        <account-plan :ref="'account-plan-' + ins.instanceId"></account-plan>
                        <el-button slot="reference" v-auth:accountPlan
                                   style="background-color: rgba(0, 0, 0, 0);outline: none;border: 0;height: 18px;line-height: 18px;margin-left: 88px;margin-top: -15px;position: absolute;padding: 0">
                          <i class="el-icon-edit-outline" style="font-size: 16px"></i></el-button>
                      </el-popover>
                    </el-row>
                    <el-row style="margin-top: 15px">
                      <el-col :span="20" :offset="2">
                    <span class="dark-mode-font"
                          :style="'font-size: 30px;' + (ins.currentBalance > ins.originBalance ? 'color: #0ecb81' : 'color: #f6465d') + ''"><b>{{
                        ((ins.currentBalance - ins.originBalance) / ins.originBalance * 100).toFixed(3)
                      }}%</b></span>
                      </el-col>
                    </el-row>
                    <el-row>
                    </el-row>
                    <el-row style="margin-top: 31px">
                      <el-card class="black-card"
                               style="height: 25px;width: 218px;margin-left: -20px;border-radius: 0;border-left: 0;border-right: 0;border-bottom: 0"
                               body-style="padding: 0;">
                        <el-row style="margin-top: -1px">
                        <span class="dark-mode-font"
                              :style="'' + (ins.currentBalance > ins.originBalance ? 'color: #0ecb81' : 'color: #f6465d') + ''"><b>{{
                            Number(Number.prototype.accSub(ins.currentBalance, ins.originBalance)).toFixed(6)
                          }}</b></span>
                        </el-row>
                      </el-card>
                    </el-row>
                  </el-card>
                </div>
              </div>
            </template>
          </el-skeleton>
        </el-row>
      </div>
      <create-account ref="createAccount" @renderEvent='render'></create-account>
      <account-config v-auth:strategyPanel ref="accountConfig" :size="configWidth" @closePositionPanel="closeAll" @refreshPosition="refreshPositionPanel"></account-config>
      <position-panel ref="positionPanel" :size="positionWidth"></position-panel>
      <settlement-page ref="settlementPage"></settlement-page>
    </div>
  </div>
</template>

<script>
import ExchangeInfo from "@/pages/controlPanel/accountMgnt/ExchangeInfo";
import CreateAccount from "@/pages/controlPanel/accountMgnt/CreateAccountDrawer";
import NProgress from 'muse-ui-progress';
import InstanceService from "@/api/trade/InstanceService";
import AccountProfitPanel from "@/pages/controlPanel/accountMgnt/ProfitPanel";
import AccountConfig from "@/pages/controlPanel/accountMgnt/AccountConfigPanel";
import ConfirmProvider from '@/components/ConfirmProvider'
import {Message} from "element-ui";
import TrialphaLoading from "@/components/TrialphaLoading";
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import UserPermissionService from "@/api/user/UserPermissionService";
import PositionPanel from "@/pages/controlPanel/accountMgnt/PositionPanel";
import Newest from "@/pages/controlPanel/accountMgnt/Newest";
import TimeService from "@/utils/TimeService";
import Optional from "@/optional/Optional";
import CacheService from "@/cache/CacheService";
import TrialphaSk from "@/components/TrialphaSk";
import AccountPlan from "@/pages/controlPanel/accountMgnt/AccountPlan";
import UserService from "@/api/user/UserService";
import SettlementPage from "@/pages/controlPanel/accountMgnt/SettlementPage";

export default {
  name: "AccountMgnt",
  components: {
    SettlementPage,
    AccountPlan,
    TrialphaSk, Newest, PositionPanel, AccountConfig, AccountProfitPanel, CreateAccount, ExchangeInfo
  },
  data: function () {
    return {
      linkAccountVisible: false,
      basicCoin: 'USD',
      basicCoinList: [
        'USD',
        'BTC',
        'ETH'
      ],
      hoverChangeName: false,
      loadingInstance: true,
      searchAccount: '',
      refreshGap: localStorage.getItem('refreshGap') || 900,
      renderAccountTimer: {},
      refreshTime: [
        {
          name: '1MIN',
          value: 60,
          divided: 'false'
        },
        {
          name: '5MIN',
          value: 300,
          divided: 'false'
        },
        {
          name: '10MIN',
          value: 600,
          divided: 'false'
        },
        {
          name: '15MIN',
          value: 900,
          divided: 'false'
        },
        {
          name: '30MIN',
          value: 1800,
          divided: 'false'
        },
        {
          name: '60MIN',
          value: 3600,
          divided: 'false'
        }
      ],
      instanceList: [],
      instanceListRender: [],
      balanceRes: {
        coin: Optional.orElse(() => CacheService.get('baseCoin'), 'USD'),
        originBalance: 0,
        currentBalance: 0,
        currentProfit: 0
      },
      linkInsId: '',
      selectExchange: 'All',
      supportExchanges: [
        'All',
        'BINANCE',
        'DYDX',
        'OKX',
        'DERIBIT',
        'BYBIT',
        'MEXC',
        'EVM_BASED_WALLET'
      ],
      selectStrategy: 'All',
      supportStrategies: [
        'All',
        'Not Configured'
      ],
      configWidth: document.documentElement.clientWidth > 1000 ? '60%' : '100%',
      positionWidth: document.documentElement.clientWidth > 1000 ? '40%' : '100%',
      exchangesCount: {
        OKX: 0,
        FTX: 0,
        GATE: 0,
        MEXC: 0,
        HUOBI: 0,
        BYBIT: 0,
        KUCOIN: 0,
        BITMEX: 0,
        DERIBIT: 0,
        BINANCE: 0,
        DYDX: 0
      },
      windowWidth: document.documentElement.clientWidth,
      timeInterval: {},
      timeInfo: 'Mon 00:00:00'
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.refreshWidth();
      })();
    };
  },
  created() {
    let queryName = this.$route.query.instanceName;
    if (queryName) {
      this.searchAccount = queryName;
    }
    this.timeInterval = setInterval(() => {
      that.timeInfo = TimeService.getSimpleTimeInfo();
    }, 1000);
    let that = this;
    let tmpStrategies = ['All'];
    StrategyInstanceService.queryAllStrategyName(function (data) {
      tmpStrategies = data;
      tmpStrategies.unshift('All');
      tmpStrategies.push('Not Configured');
      that.supportStrategies = tmpStrategies;
      that.render(true);
    });
    this.renderAccountTimer = setInterval(this.render, this.refreshGap * 1000);
  },
  destroyed: function () {
    clearInterval(this.timeInterval);
    clearInterval(this.renderAccountTimer);
  },
  methods: {
    renderAccountPlan(instance) {
      this.$refs['account-plan-' + instance.instanceId][0].render(instance);
    },
    hasPermission(featureName) {
      return UserPermissionService.isAllowed(featureName);
    },
    refreshWidth: function () {
      window.fullWidth = document.documentElement.clientWidth;
      this.windowWidth = window.fullWidth;
      if (UserPermissionService.getFeaturePermission('strategyPanel') === UserPermissionService.permission_type.ALLOWED) {
        if (this.windowWidth > 1000) {
          this.configWidth = '60%';
          this.positionWidth = '40%'
        } else {
          this.configWidth = '100%';
          this.positionWidth = '100%'
        }
      } else if (UserPermissionService.getFeaturePermission('accountInfoPanel') === UserPermissionService.permission_type.ALLOWED) {
        this.configWidth = '0';
        this.positionWidth = '100%'
      }
    },
    renderCurrentInstance: function () {
      let that = this;
      let renderIndex = 0;
      for (let i = this.instanceListRender.length; i < this.instanceList.length; i++) {
        this.instanceListRender.push(this.instanceList[i]);
        if (that.$refs['profit-panel-' + this.instanceList[i].instanceId] && that.$refs['profit-panel-' + this.instanceList[i].instanceId][0]) {
          that.$refs['profit-panel-' + this.instanceList[i].instanceId][0].render();
        }
        renderIndex++;
        if (renderIndex >= UserService.getUserObj().loadPageSize) {
          return;
        }
      }
    },
    loadInstanceList: function (afterInvoke) {
      let that = this;
      InstanceService.query(function (data) {
        let tmpData = data.filter((obj) => {
          //筛选账号名
          if (that.searchAccount === '' || obj.name.toUpperCase().indexOf(that.searchAccount.toUpperCase()) > -1) {
            //筛选交易所
            if (that.selectExchange === 'All') {
              //筛选策略
              if (that.selectStrategy === 'All') {
                return obj;
              } else {
                if (obj.strategyInstances.filter((strategy) => {
                  if (that.selectStrategy === strategy.strategyName) {
                    return strategy;
                  }
                }).length > 0) {
                  return obj;
                } else if (that.selectStrategy === 'Not Configured' && obj.strategyInstances.length === 0) {
                  return obj;
                }
              }
            } else {
              if (obj.tradeAccountList.filter((account) => {
                if (that.selectExchange === account.platform) {
                  return account;
                }
              }).length > 0) {
                if (obj.strategyInstances.filter((strategy) => {
                  if (that.selectStrategy === 'All' || that.selectStrategy === strategy.strategyName) {
                    return strategy;
                  }
                }).length > 0) {
                  return obj;
                } else if (that.selectStrategy === 'All' || (that.selectStrategy === 'Not' && obj.strategyInstances.length === 0)) {
                  return obj;
                }
              }
            }
          }
        });
        that.instanceList = tmpData;
        for (const tmpDataKey in tmpData) {
          let obj = tmpData[tmpDataKey];
          obj.tradeAccountList.forEach((account) => {
            that.exchangesCount[account.platform] = that.exchangesCount[account.platform] + 1;
          });
        }
        that.instanceListRender = [];
        that.renderCurrentInstance();
        if (afterInvoke) {
          afterInvoke();
        }
      }, function () {
        if (afterInvoke) {
          afterInvoke();
        }
      });
    },
    linkAccount: function () {
      NProgress.start();
      let that = this;
      that.linkAccountVisible = false;
      InstanceService.link(that.linkInsId.replace('Link ID: ', ''), function () {
        Message.success('成功关联实例 ' + that.linkInsId.replace('Link ID: ', ''));
        NProgress.done();
        that.loadInstanceList();
      }, function () {
        NProgress.done();
      });
    },
    delAccount: function (id) {
      let that = this;
      ConfirmProvider.openConfirm(that, '删除操作', '您要确认删除该账号吗，此操作不可逆。', function () {
        NProgress.start();
        let loading = TrialphaLoading.loading(that);
        InstanceService.deleteAccount(id, function () {
          loading.close();
          Message.success('成功删除实例 ' + id);
          NProgress.done();
          that.loadInstanceList();
        }, function () {
          loading.close();
          NProgress.done();
        });
      });
    },
    moreAction: function (command) {
      let that = this;
      if (command.action === 'forceRefresh') {
        let loading = TrialphaLoading.loading(that);
        InstanceService.forceRefresh(command.id, function () {
          loading.close();
          Message.info('账号已强制刷新');
          that.loadInstanceList();
          that.$refs['profit-panel-' + command.id][0].render();
        }, function () {
          loading.close();
        });
      } else if (command.action === 'rename') {
        ConfirmProvider.openConfirmWithInput(this, '请输入新昵称', '', function (value) {
          if (value === command.name) {
            Message.warning('未修改名称');
          } else {
            that.instanceList.forEach((obj) => {
              if (obj.instanceId === command.id) {
                obj.name = value;
              }
            });
            InstanceService.updateName({
              instanceId: command.id,
              instanceName: value
            }, function () {
              Message.info(command.name + ' 已成功修改为 ' + value);
            });
          }
        }, command.name);
      } else if (command.action === 'reset') {
        if (!UserPermissionService.isAllowed('resetAccount')) {
          return;
        }
        ConfirmProvider.openConfirm(this, '操作确认', '请确定重置该账号', function () {
          let loading = TrialphaLoading.loading(that);
          InstanceService.reset(command.id, function () {
            loading.close();
            Message.info('账号已重置成功');
            that.loadInstanceList();
            that.$refs['profit-panel-' + command.id][0].render();
          }, function () {
            loading.close();
          });
        });
      } else if (command.action === 'unlink') {
        ConfirmProvider.openConfirm(this, '操作确认', '请确定解除该账号的关联', function () {
          let loading = TrialphaLoading.loading(that);
          InstanceService.unlink(command.id, function () {
            loading.close();
            Message.info('账号已解除关联');
            that.loadInstanceList();
            that.$refs['profit-panel-' + command.id][0].render();
          }, function () {
            loading.close();
          });
        });
      } else if (command.action === 'delete') {
        if (!UserPermissionService.isAllowed('deleteAccount')) {
          return;
        }
        this.delAccount(command.id);
      } else if (command.action === 'bindCustomer') {
        let cname = command.ins.customerInfo.name;
        // cname为空时则绑定
        if (cname === null) {
          ConfirmProvider.openConfirmWithRegex(this, '请输入客户名', '', function (value) {
            if (value === cname) {
              Message.warning('未修改名称');
            } else {
              let loading = TrialphaLoading.loading(that);
              command.ins.customerInfo.name = value;
              InstanceService.bindCustomer(command.ins.instanceId, value
                  , function () {
                    loading.close();
                    that.loadInstanceList();
                    that.$refs['profit-panel-' + command.ins.instanceId][0].render();
                    if (value === 'remove') {
                      Message.info("取消关联成功")
                    } else {
                      Message.info((cname === null || cname === 'remove' ? "" : cname) + ' 已成功绑定客户为 ' + value);
                    }
                  }, function (e) {
                    Message.error("更新失败 " + e.data())
                    command.ins.customerInfo.name = cname
                    loading.close();
                  });
            }
          }, command.name, /^.{1,25}$/);
        } else {
          // 非空则提示接触绑定
          ConfirmProvider.openConfirm(this, '是否解除绑定', '', function () {
            let loading = TrialphaLoading.loading(that);
            command.ins.customerInfo.name = null;
            InstanceService.bindCustomer(command.ins.instanceId, "remove"
                , function () {
                  loading.close();
                  that.loadInstanceList();
                  that.$refs['profit-panel-' + command.ins.instanceId][0].render();
                  Message.info("解除绑定成功")
                }, function (e) {
                  Message.error("更新失败 " + e.data())
                  command.ins.customerInfo.name = cname
                  loading.close();
                });
          });
        }
      } else if (command.action === 'settlementPage') {
        that.$refs['settlementPage'].render(command.ins);
      }

    },
    openAccountConfig: function (instance) {
      this.refreshWidth();
      let that = this;
      UserPermissionService.isAllowedAndThen('configAccount', function () {
        window.$crisp.push(["do", "chat:hide"])
        that.$nextTick(function () {
          if (UserPermissionService.getFeaturePermission('strategyPanel') === UserPermissionService.permission_type.ALLOWED) {
            that.$refs['accountConfig'].openDrawer(instance);
            if (that.windowWidth > 1000) {
              that.$refs['positionPanel'].render(instance, that.positionWidth);
            }
          } else if (UserPermissionService.getFeaturePermission('accountInfoPanel') === UserPermissionService.permission_type.ALLOWED) {
            if (that.windowWidth > 1000) {
              that.$refs['positionPanel'].render(instance, that.positionWidth);
            }
          }
        });
      });
    },
    openPositionConfig: function (instance) {
      this.refreshWidth();
      let that = this;
      UserPermissionService.isAllowedAndThen('configAccount', function () {
        window.$crisp.push(["do", "chat:hide"]);
        if (UserPermissionService.getFeaturePermission('accountInfoPanel') === UserPermissionService.permission_type.ALLOWED) {
          that.$refs['positionPanel'].render(instance, that.positionWidth);
        }
      });
    },
    changeCoin: function () {
      let that = this;
      NProgress.start();
      CacheService.set('baseCoin', that.balanceRes.coin, 1000 * 60 * 60 * 24 * 365);
      InstanceService.queryBalance(that.balanceRes.coin, function (data) {
        that.balanceRes = data;
        NProgress.done();
      }, function () {
        NProgress.done();
      });
    },
    changeRefresh: function (item) {
      clearInterval(this.renderAccountTimer);
      if (item > 0) {
        let that = this;
        that.renderAccountTimer = setInterval(() => {
          that.render();
        }, 1000 * item);
        that.refreshGap = item;
      }
      localStorage.setItem('refreshGap', item);
    },
    openCreateAccountDrawer: function () {
      this.$nextTick(function () {
        this.$refs['createAccount'].openDrawer();
      });
    },
    render: function (showLoading) {
      NProgress.start();
      let that = this;
      that.exchangesCount = {
        OKX: 0,
        FTX: 0,
        DYDX: 0,
        GATE: 0,
        MEXC: 0,
        HUOBI: 0,
        BYBIT: 0,
        KUCOIN: 0,
        BITMEX: 0,
        DERIBIT: 0,
        BINANCE: 0
      };
      if (showLoading) {
        that.loadingInstance = true;
      }
      InstanceService.queryBalance(that.balanceRes.coin, function (data) {
        that.balanceRes = data;
      });
      that.loadInstanceList(function () {
        NProgress.done();
        that.loadingInstance = false;
      });
    },
    getStrategyListString: function (strategyInstances) {
      if (strategyInstances.length === 0) {
        return 'Not Configured';
      } else {
        let strategyString = [];
        strategyInstances.forEach((ins) => {
          if (strategyString.indexOf(ins.strategyName) === -1) {
            strategyString.push(ins.strategyName);
          }
        });
        return strategyString.toString();
      }
    },
    hasError: function (ins) {
      if (ins.strategyInstances.length === 0) {
        return false;
      }
      let errorList = ins.strategyInstances.filter((strategy) => {
        if (strategy.strategyConfig.errorMessage !== null && strategy.strategyConfig.errorMessage !== undefined && strategy.strategyConfig.errorMessage !== 'null' && strategy.strategyConfig.errorMessage !== 'NULL' && strategy.strategyConfig.errorMessage !== '') {
          return strategy.strategyConfig.errorMessage;
        }
      });
      return errorList.length > 0;
    },
    getError: function (ins) {
      if (ins.strategyInstances.length === 0) {
        return '';
      }
      let errorList = [];
      ins.strategyInstances.forEach((strategy) => {
        if (strategy.strategyConfig.errorMessage !== null && strategy.strategyConfig.errorMessage !== undefined && strategy.strategyConfig.errorMessage !== 'null' && strategy.strategyConfig.errorMessage !== 'NULL' && strategy.strategyConfig.errorMessage !== '') {
          errorList.push(strategy.strategyName + ':' + strategy.strategyConfig.symbolDescription + ' ' + strategy.strategyConfig.errorMessage);
        }
      });
      return errorList;
    },
    closeAll: function () {
      this.$refs['positionPanel'].close();
    },
    refreshPositionPanel: function () {
      if (this.windowWidth > 1000) {
        this.$refs['positionPanel'].renderAccount();
      }
    }
  }
}
</script>

<style>

.account-full-panel {
  width: 100%;
  margin-left: 0;
  height: 100vh;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
  margin-top: 0;
  -webkit-overflow-scrolling: touch;
}

@media only screen and  (max-width: 991px) {
  .account-full-panel {
    margin-top: 160px;
    height: calc(100vh - 155px);
  }
}

.ellipsis-line-strategy {
  padding: 0;
  text-align: left;
  width: 268px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and  (max-width: 568px) {
  .ellipsis-line-strategy {
    padding: 0;
    text-align: left;
    width: 168px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.ellipsis-line-strategy-ins-name {
  padding: 0;
  text-align: center;
  width: 198px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-divider.info {
  width: 80%;
  border-top: 1px solid #444c56;
}

.el-divider.mgnt-divider {
  border-top: 1px solid #444c56;
}

.mgnt-divider > .el-divider__text {
  background-color: #1c2128;
}

.searchAccount .el-input__inner {
  color: white;
  height: 35px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 0;
  border-right: 0;
  outline: none;
}

.searchAccount .el-input__inner:hover {
  outline: none;
  border: 1px solid #444c56;
}

.searchAccount .el-input__inner:focus {
  outline: none;
  border: 1px solid #444c56;
}

.el-progress-circle__track {
  stroke: #373e47;
}

.select-basic-coin .el-input__inner {
  color: white;
  height: 28px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 3px;
  font-size: 12px;
  outline: none;
}

.select-basic-coin.el-select .el-input.is-focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-basic-coin.el-select .el-input--suffix .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-basic-coin.el-select .el-input--suffix:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-basic-coin.el-select .el-select:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-basic-coin.el-select .el-select:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-basic-coin.el-select .el-select:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-basic-coin.el-select .el-input--suffix:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-basic-coin.el-select .el-input--suffix:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.search-pc.el-input {
  width: 168px;
  height: 35px;
}

.select-exchange .el-input__inner {
  color: white;
  height: 35px !important;
  width: 138px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
  outline: none;
}

.select-exchange.el-select .el-input.is-focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange.el-select .el-input--suffix .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange.el-select .el-input--suffix:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange.el-select .el-select:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange.el-select .el-select:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange.el-select .el-select:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange.el-select .el-input--suffix:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange.el-select .el-input--suffix:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange-small .el-input__inner {
  color: white;
  height: 35px !important;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 3px;
  font-size: 12px;
}


.select-exchange-small.el-select .el-input.is-focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange-small.el-select .el-input--suffix .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange-small.el-select .el-input--suffix:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange-small.el-select .el-select:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange-small.el-select .el-select:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange-small.el-select .el-select:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange-small.el-select .el-input--suffix:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-exchange-small.el-select .el-input--suffix:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.mobile-e .el-input__inner {
  border-radius: 3px 0 0 3px;
}

.mobile-s .el-input__inner {
  border-radius: 0;
}

.searchAccount-mobile .el-input__inner {
  color: white;
  height: 35px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 0;
  border-right: 0;
}

.searchAccount-mobile .el-input__inner:hover {
  outline: none;
  border: 1px solid #444c56;
}

.searchAccount-mobile .el-input__inner:focus {
  outline: none;
  border: 1px solid #444c56;
}


.search-m.el-input {
  width: 100%;
  height: 30px;
  margin-left: 0;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(119, 109, 109, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(119, 109, 109, 0.5);
}

.account-panel-inner {
  border: 0;
  margin-top: 20px;
  height: 138px;
  border-radius: 0;
  display: flex;
}

@media only screen and  (max-width: 991px) {
  .account-panel-inner {
    border: 0;
    margin-top: 60px;
    height: 138px;
    border-radius: 0;
    display: flex;
  }
}

.account-panel-out {
  margin-top: 0;
  margin-bottom: 20px
}

@media only screen and  (max-width: 991px) {
  .account-panel-out {
    z-index: 2;
    margin-top: 0;
    margin-bottom: 20px
  }
}

@-webkit-keyframes shineRed {
  from {
    -webkit-box-shadow: 0 0 5px #f5b6b6;
  }
  50% {
    -webkit-box-shadow: 0 0 10px #ff0000;
  }
  to {
    -webkit-box-shadow: 0 0 5px #f5b6b6;
  }
}

.shine_red {
  -webkit-animation-name: shineRed;
  -webkit-animation-duration: 0.8s;
  -webkit-animation-iteration-count: infinite;
}

.el-tooltip:focus:hover, .el-tooltip:focus:not(.focusing){
  outline: none;
}

</style>