import { render, staticRenderFns } from "./HedgeCloseBinance.vue?vue&type=template&id=72d12cd5&scoped=true"
import script from "./HedgeCloseBinance.vue?vue&type=script&lang=js"
export * from "./HedgeCloseBinance.vue?vue&type=script&lang=js"
import style0 from "./HedgeCloseBinance.vue?vue&type=style&index=0&id=72d12cd5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d12cd5",
  null
  
)

export default component.exports