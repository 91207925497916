<template>
  <div class="trialpha-stander-sub-page" style="padding-bottom: 100px">
    <div style="margin: 56px auto auto auto;max-width: 2068px;padding: 0 6.18% 100px 6.18%">
      <div class="article-manager">
        <el-row :gutter="20">
          <el-col :span="16">
            <div class="article-editor black-card" style="">
              <el-form class="black-card" :model="article" label-width="auto"
                       style="border: 0;background-color: rgba(85,107,114,0);max-width: 600px">
                <el-form-item style="color: white" label="文章标题">
                  <el-input v-model="article.title"/>
                </el-form-item>
                <el-form-item label="文章唯一key">
                  <el-input v-model="article.titleKey" placeholder="文章的唯一标志，默认可以输入正常的英文/中文标题"/>
                </el-form-item>

                <el-form-item label="发布日期">
                  <el-date-picker
                      v-model="article.publishDateStr"
                      type="datetime"
                      style="width: 100%;"
                      placeholder="选择日期时间"
                      default-time="12:00:00"
                      format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>

                <el-form-item label="描述">
                  <el-input v-model="article.desc" placeholder="文章的简介"/>
                </el-form-item>
                <el-form-item label="更多内容链接">
                  <el-input v-model="article.meta.moreContentURL" placeholder="输入URL，让用户可以额外点击的地方"/>
                </el-form-item>

                <el-form-item label="作者">
                  <el-input v-model="article.author"/>
                </el-form-item>
                <el-form-item label="语言选择">
                  <el-select style="width: 100%" v-model="article.language" placeholder="请选择语言">
                    <el-option label="中文" value="cn"/>
                    <el-option label="English" value="en"/>
                  </el-select>
                </el-form-item>


                <el-form-item label="标签选择">
                  <el-select style="width: 100%" v-model="article.meta.type" placeholder="请选择标签">
                    <el-option label="投研动态" value="invest_news"></el-option>
                    <el-option label="公司动态" value="company_news"></el-option>
                    <el-option label="招聘" value="jobs"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item v-if="article.meta.type=='invest_news'" label="投资新闻分类">
                  <el-select style="width: 100%" v-model="article.meta.investNewsCategory" placeholder="Select">
                    <el-option
                        key="1"
                        label="周报"
                        value="周报">
                    </el-option>
                  </el-select>
                </el-form-item>


                <el-form-item v-if="article.meta.type=='company_news'" label="公司新闻分类">
                  <el-select style="width: 100%" v-model="article.meta.companyNewsCategory" placeholder="Select">
                    <el-option
                        key="1"
                        label="公司活动"
                        value="公司活动">
                    </el-option>
                    <el-option
                        key="2"
                        label="合作"
                        value="合作">
                    </el-option>
                  </el-select>
                </el-form-item>


                <el-form-item v-if="article.meta.type=='jobs'" label="工作部门">
                  <el-select style="width: 100%" v-model="article.meta.jobDepartment" placeholder="Select">
                    <el-option
                        key="1"
                        label="量化投资"
                        value="量化投资">
                    </el-option>
                    <el-option
                        key="2"
                        label="基金运营"
                        value="基金运营">
                    </el-option>
                    <el-option
                        key="3"
                        label="市场营销"
                        value="市场营销">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="article.meta.type=='jobs'" label="职位名称">
                  <el-input style="width: 100%" v-model="article.meta.jobName" placeholder="请输入职位名称"/>
                </el-form-item>
                <el-form-item v-if="article.meta.type=='jobs'" label="工作地点">
                  <el-input style="width: 100%" v-model="article.meta.jobLocation" placeholder="请输入工作地点"/>
                </el-form-item>

                <el-form-item label="文章封面">
                  <el-upload
                      :before-upload="beforeArticleCoverUpload"
                      :show-file-list="false"
                      action="#"
                      class="avatar-uploader" style="color: white;font-weight: bolder">
                    <img v-if="article.coverImgURL" :src="article.coverImgURL" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
                <el-form-item>
                  <el-button class="dark-mode-btn" style="width: 100%" @click="saveArticle" :loading="saving">发布文章
                  </el-button>
                </el-form-item>
                <el-form-item>
                  <el-button class="dark-mode-btn" style="width: 100%" @click="saveArticleToCache" :loading="saving">保存草稿
                  </el-button>
                </el-form-item>
              </el-form>

              <div class="editor-container" style="background-color: white">
                <quill-editor :content="article.content" :options="options"
                              @change="onEditorChange($event)"></quill-editor>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="article-list" style="border-bottom: 0;border-radius: 5px 5px 0 0">
              <!-- Header -->
              <el-card class="black-card" style="border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #1c2128"
                       body-style="padding: 10px" shadow="none">
                <el-row>
                  <el-col :span="8" style="text-align: left">
                    <span class="dark-mode-font" style="color: white">已保存的草稿</span>
                  </el-col>
                  <el-col :span="16">
                    <input class="dark-mode-input" v-model="filterArticleName" placeholder="Filter..."
                           style="color: white;height: 25px;outline: none;font-size: 12px;background-color: rgba(0, 0, 0, 0);border: 0;width: 98px;float: right;border-bottom: 1px solid #444c56;padding-left: 18px;padding-bottom: 1px"/>
                    <i class="el-icon-search"
                       style="float: right;color: #444c56;margin-top: 5px;margin-right: -15px"></i>
                  </el-col>
                </el-row>
              </el-card>
              <!-- 表头 -->
              <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none"
                       body-style="padding: 4px 10px 3px 10px">
                <el-row>
                  <el-col :span="18">
            <span class="dark-mode-font"
                  style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">文章</span>
                  </el-col>
                  <el-col :span="6">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">操作</span>
                  </el-col>
                </el-row>
              </el-card>
              <!-- 数据展示 -->
              <el-card class="black-card"
                       style="border-radius: 0;border-top: 0;max-height: 30vh;overflow-y: auto"
                       shadow="none"
                       body-style="padding: 0">
                <el-skeleton animated :loading="false" :throttle="200">
                  <template slot="template">
                    <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left: 10px'"></trialpha-sk>
                    <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                    <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                    <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left: 10px'"></trialpha-sk>
                    <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                    <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                    <trialpha-sk
                        style="margin-top: 20px;margin-bottom: 20px;width: 90%;float: left;margin-left: 10px"></trialpha-sk>
                  </template>
                  <template>
                    <div v-if="draftArticles.length > 0">
                      <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0"
                               body-style="padding:0"
                               v-for="(a, index) in draftArticles" :key="index">
                        <el-row style="height: 50px;border-bottom: 1px solid #2e3033;padding: 3px 0 3px 10px">
                          <el-col :span="18">
                            <el-row>
                    <span class="dark-mode-font ellipsis-line"
                          style="display: inline-block;float: left;text-align: left;font-size: 14px;color:#e5e1e1;">{{
                        '[' + a['language'].toUpperCase() + '] ' + a['title']
                      }}</span>
                            </el-row>
                            <el-row style="margin-top: 3px">
                    <span class="dark-mode-font ellipsis-line"
                          style="display: inline-block;float: left;text-align: left;font-size: 12px;color:#83b9c4; ">{{
                        a['author'] + ' | ' + a['publishDateStr']
                      }}</span>
                            </el-row>
                          </el-col>
                          <el-col :span="3">
                            <el-button class="dark-mode-btn"
                                       style="border-radius: 25px;border-top: 0;border-bottom: 0;border-right: 0;height: 26px;width: 26px;line-height: 25px;float: right;margin-top: 10px;font-size: 12px;padding: 0;"
                                       @click="loadArticleFromCache(a['titleKey'], a['language'])">
                              <i class="el-icon-edit-outline"></i>
                            </el-button>
                          </el-col>
                          <el-col :span="3">
                            <el-button class="dark-mode-btn"
                                       style="border-radius: 25px;border-top: 0;border-bottom: 0;border-right: 0;height: 26px;width: 26px;line-height: 25px;margin-top: 10px;font-size: 12px;padding: 0;"
                                       @click="delArticleFromCache(a['titleKey'], a['language'], false)">
                              <i class="el-icon-document-delete"></i>
                            </el-button>
                          </el-col>
                        </el-row>
                      </el-card>
                    </div>
                    <div v-if="draftArticles.length === 0">
                      <el-row
                          style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i
                    class="el-icon-s-order"/> EMPTY ...</span>
                      </el-row>
                    </div>
                  </template>
                </el-skeleton>
              </el-card>
            </div>
            <div class="article-list" style=";border-radius: 0 0 5px 5px">
              <!--
              <el-table class="black-card" :data="articles" style="width: 100%; border-radius: 5px">
                <el-table-column :label="$i('cms.title')" prop="title"></el-table-column>
                <el-table-column :label="$i('cms.language')" prop="language" width="100">
                  <template slot-scope="scope">
                    {{ scope.row.language === 'cn' ? '中文' : 'English' }}
                  </template>
                </el-table-column>
                <el-table-column :label="$i('cms.author')" prop="author" width="120"></el-table-column>
                <el-table-column :label="$i('cms.operation')" fixed="right" width="180">
                  <template slot-scope="scope">
                    <el-button size="small" type="text" @click="loadArticle(scope.row.titleKey, scope.row.language)">
                      {{ $i('cms.edit') }}
                    </el-button>
                    <el-button size="small" type="text" @click="deleteArticle(scope.row.titleKey, scope.row.language)">
                      {{ $i('cms.delete') }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              -->
              <!-- Header -->
              <el-card class="black-card" style="border-radius: 0;border-bottom: 0;background-color: #1c2128"
                       body-style="padding: 10px" shadow="none">
                <el-row>
                  <el-col :span="8" style="text-align: left">
                    <span class="dark-mode-font" style="color: white">已发布的文章</span>
                  </el-col>
                  <el-col :span="16">
                    <input class="dark-mode-input" v-model="filterArticleName" placeholder="Filter..."
                           style="color: white;height: 25px;outline: none;font-size: 12px;background-color: rgba(0, 0, 0, 0);border: 0;width: 98px;float: right;border-bottom: 1px solid #444c56;padding-left: 18px;padding-bottom: 1px"/>
                    <i class="el-icon-search"
                       style="float: right;color: #444c56;margin-top: 5px;margin-right: -15px"></i>
                  </el-col>
                </el-row>
              </el-card>
              <!-- 表头 -->
              <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none"
                       body-style="padding: 4px 10px 3px 10px">
                <el-row>
                  <el-col :span="18">
            <span class="dark-mode-font"
                  style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">文章</span>
                  </el-col>
                  <el-col :span="6">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">操作</span>
                  </el-col>
                </el-row>
              </el-card>
              <!-- 数据展示 -->
              <el-card class="black-card"
                       style="border-radius: 0;border-top: 0;max-height: 80vh;overflow-y: auto"
                       shadow="none"
                       body-style="padding: 0">
                <el-skeleton animated :loading="false" :throttle="200">
                  <template slot="template">
                    <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left: 10px'"></trialpha-sk>
                    <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                    <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                    <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left: 10px'"></trialpha-sk>
                    <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                    <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                    <trialpha-sk
                        style="margin-top: 20px;margin-bottom: 20px;width: 90%;float: left;margin-left: 10px"></trialpha-sk>
                  </template>
                  <template>
                    <div v-if="articles.length > 0">
                      <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0"
                               body-style="padding:0"
                               v-for="(a, index) in articles" :key="index">
                        <el-row style="height: 50px;border-bottom: 1px solid #2e3033;padding: 3px 0 3px 10px">
                          <el-col :span="18">
                            <el-row>
                    <span class="dark-mode-font ellipsis-line"
                          style="display: inline-block;float: left;text-align: left;font-size: 14px;color:#e5e1e1;">{{
                        '[' + a['language'].toUpperCase() + '] ' + a['title']
                      }}</span>
                            </el-row>
                            <el-row style="margin-top: 3px">
                    <span class="dark-mode-font ellipsis-line"
                          style="display: inline-block;float: left;text-align: left;font-size: 12px;color:#83b9c4; ">{{
                        a['author'] + ' | ' + a['publishDateStr']
                      }}</span>
                            </el-row>
                          </el-col>
                          <el-col :span="3">
                            <el-button class="dark-mode-btn"
                                       style="border-radius: 25px;border-top: 0;border-bottom: 0;border-right: 0;height: 26px;width: 26px;line-height: 25px;float: right;margin-top: 10px;font-size: 12px;padding: 0;"
                                       @click="loadArticle(a['titleKey'], a['language'])">
                              <i class="el-icon-edit-outline"></i>
                            </el-button>
                          </el-col>
                          <el-col :span="3">
                            <el-button class="dark-mode-btn"
                                       style="border-radius: 25px;border-top: 0;border-bottom: 0;border-right: 0;height: 26px;width: 26px;line-height: 25px;margin-top: 10px;font-size: 12px;padding: 0;"
                                       @click="deleteArticle(a['titleKey'], a['language'])">
                              <i class="el-icon-document-delete"></i>
                            </el-button>
                          </el-col>
                        </el-row>
                      </el-card>
                    </div>
                    <div v-if="articles.length === 0">
                      <el-row
                          style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i
                    class="el-icon-s-order"/> EMPTY ...</span>
                      </el-row>
                    </div>
                  </template>
                </el-skeleton>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleManagementAPI from "@/api/cms/ArticleManagementAPI.vue";
import Quill from "quill";
import QuillImageUploader from 'quill-image-uploader';
import ConfirmProvider from "../../../components/ConfirmProvider.vue";
import TrialphaSk from "@/components/TrialphaSk.vue";
import {Message} from "element-ui";
import CacheService from "@/cache/CacheService.vue";

Quill.register('modules/image-uploader', QuillImageUploader);

export function imageHandler() {
  const quill = this.quill;

  const input = document.createElement('input');

  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    const formData = new FormData();

    formData.append('image', file);

    // Save current cursor state
    const range = quill.getSelection(true);

    // Insert temporary loading placeholder image
    quill.insertEmbed(range.index, 'image', 'https://cdn.dribbble.com/users/1341307/screenshots/5377324/morph_dribbble.gif');

    // Move cursor to right side of image (easier to continue typing)
    quill.setSelection(range.index + 1);

    // THIS NEEDS TO BE HOOKED UP TO AN API
    // RESPONSE DATA WILL THEN BE USED TO EMBED THE IMAGE
    // const res = await apiPostNewsImage(formData);

    // Remove placeholder image
    quill.deleteText(range.index, 1);

    try {
      console.info('before');
      await ArticleManagementAPI.uploadImage(formData,
          (data) => {
            console.info('res', data);
            quill.insertEmbed(range.index, 'image', data);
          },
          (err) => {
            console.error('error', err);
          }
      );
    } catch (err) {
      console.error('error', err)
    }

  }
}

export default {
  name: "ArticlePublish",
  components: {TrialphaSk},
  data() {
    return {
      options: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{'header': 1}, {'header': 2}],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{'script': 'sub'}, {'script': 'super'}],
              [{'indent': '-1'}, {'indent': '+1'}],
              [{'direction': 'rtl'}],
              [{'size': ['small', false, 'large', 'huge']}],
              [{'header': [1, 2, 3, 4, 5, 6, false]}],
              [{'color': []}, {'background': []}],
              [{'font': []}],
              [{'align': []}],
              ['clean'],
              ['link', 'image', 'video', 'formula'],
            ],
            handlers: {
              image: imageHandler,
            }
          }
        }
      },
      article: {
        title: '',
        titleKey: '',
        content: '',
        language: 'en',
        author: 'ForthTech',
        desc: '',
        publishDateStr: '',
        labels: [], // Ensure this is initialized as an empty array
        coverImgURL: '',
        meta: {
          type: '',
          jobName: '', // Add jobName to article data
          jobLocation: '',
          jobDepartment: '',
          investNewsCategory: '',
          companyNewsCategory: '',
          moreContentURL: '',
        }, // Add jobLocation to article data
        moreContentURL: ''
      },
      articles: [],
      draftArticles: [],
      filterArticleName: '',
      saving: false
    };
  },
  mounted() {
    this.fetchArticles();
    // this.checkForDraft();
  },
  methods: {
    saveArticle() {
      this.saving = true;
      delete this.article['publishDate'];
      const date = new Date(this.article.publishDateStr);
      this.article.publishDateStr = date.getFullYear() + '-' +
      String(date.getMonth() + 1).padStart(2, '0') + '-' +
      String(date.getDate()).padStart(2, '0') + ' ' +
      String(date.getHours()).padStart(2, '0') + ':' +
      String(date.getMinutes()).padStart(2, '0') + ':' +
      String(date.getSeconds()).padStart(2, '0');
      ArticleManagementAPI.saveArticle(this.article,
          () => {
            this.$message.success('Article saved successfully');
            this.saving = false;
            // Remove the saved article from session storage after successful save
            // const key = `article_${this.article.language}_${this.article.titleKey}`;
            // sessionStorage.removeItem(key);
            // this.cleanupOldDrafts()
            this.delArticleFromCache(this.article.titleKey, this.article.language, true);
            this.fetchArticles();
            this.resetForm();
          },
          (err) => {
            console.error('error', err);
            this.$message.error('Error saving article: ' + err.response.data);
            this.saving = false;
          }
      );
    },
    loadArticle(titleKey, language) {
      // First, try to load from session storage
      // this.loadArticleFromSessionStorage(language, titleKey);

      // If not found in session storage, load from API
      ArticleManagementAPI.loadArticle(titleKey, language,
          (data) => {
            this.article = data;
            // this.saveArticleToSessionStorage();
          },
          (err) => {
            this.$message.error('Error loading article: ' + err.message);
          }
      );
    },
    fetchArticles() {
      this.draftArticles = CacheService.get('DraftArticles') || [];
      ArticleManagementAPI.getArticleList('',
          (data) => {
            this.articles = data;
          },
          (err) => {
            this.$message.error('Error fetching articles: ' + err.message);
          }
      );
    },
    onEditorChange({html}) {
      this.article.content = html;
      // this.saveArticleToSessionStorage();
    },
    imageHandler(file) {
      console.info('fileee', file);
    },
    resetForm() {
      this.article = {
        title: '',
        titleKey: '',
        content: '',
        language: 'en',
        author: 'ForthTech',
        labels: [],
        coverImgURL: '',
        publishDateStr: '',
        desc: '',
        meta: {
          type: '',
          jobName: '', // Add jobName to article data
          jobLocation: '',
          jobDepartment: '',
          investNewsCategory: '',
          moreContentURL: '',
          companyNewsCategory: ''
        }, // Add jobLocation to article data
      };
      // this.saveArticleToSessionStorage();
    },
    beforeArticleCoverUpload(file) {
      const formData = new FormData();
      formData.append('image', file);

      ArticleManagementAPI.uploadImage(formData,
          (data) => {
            this.article.coverImgURL = data;
            this.$message.success('Article cover uploaded successfully');
          },
          (err) => {
            console.error('error', err);
            this.$message.error('Error uploading article cover: ' + err.message);
          }
      );

      return false; // Prevent el-upload from automatically uploading
    },
    deleteArticle(titleKey, language) {
      ConfirmProvider.openConfirm(this, 'Warning', 'Are you sure you want to delete this article? Can not be undone.', () => {
        ArticleManagementAPI.deleteArticle(titleKey, language,
            () => {
              this.$message.success('Article deleted successfully');
              this.fetchArticles();
            },
            (err) => {
              console.error('error', err);
              this.$message.error('Error deleting article: ' + err.message);
            }
        )
      });
    },
    saveArticleToCache() {
      if (!this.article.title || !this.article.titleKey) {
        Message.warning('文章标题或文章唯一Key缺失，无法保存')
        return;
      }
      let draftList = CacheService.get('DraftArticles');
      if (!draftList) {
        draftList = [this.article];
      } else {
        let hasDraft = false;
        for (const draftListKey in draftList) {
          let articleDraft = draftList[draftListKey];
          if (articleDraft.titleKey === this.article.titleKey && articleDraft.language === this.article.language) {
            hasDraft = true;
            draftList[draftListKey] = this.article
          }
        }
        if (!hasDraft) {
          draftList.push(this.article);
        }
      }
      CacheService.set('DraftArticles', draftList);
      this.draftArticles = CacheService.get('DraftArticles') || [];
      Message.info('草稿保存成功（草稿仅在本设备有效）')
    },
    loadArticleFromCache(titleKey, language) {
      let draftList = CacheService.get('DraftArticles');
      if (!draftList) {
        Message.warning('当前没有任何草稿内容（请注意，您的草稿只在本地保存，您无法获取其他设备中保存的草稿内容）');
        return;
      }
      for (const draftListKey in draftList) {
        let articleDraft = draftList[draftListKey];
        if (articleDraft.titleKey === titleKey && articleDraft.language === language) {
          this.article = articleDraft
          return;
        }
      }
    },
    delArticleFromCache(titleKey, language, skipConfirm) {
      function _del(that, titleKey, language) {
        let draftList = CacheService.get('DraftArticles');
        if (!draftList) {
          return;
        }
        for (const draftListKey in draftList) {
          let articleDraft = draftList[draftListKey];
          if (articleDraft.titleKey === titleKey && articleDraft.language === language) {
            draftList.splice(draftListKey, 1);
            break;
          }
        }
        CacheService.set('DraftArticles', draftList);
        that.draftArticles = CacheService.get('DraftArticles') || [];
      }
      if (skipConfirm) {
        _del(this, titleKey, language);
      } else {
        ConfirmProvider.openConfirm(this, 'Warning', 'Are you sure you want to delete this article? Can not be undone.', () => {
          _del(this, titleKey, language);
          Message.info('已删除一篇草稿');
        });
      }
    },

    getAllDraftsFromSessionStorage() {
      const drafts = [];
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key.startsWith('article_')) {
          drafts.push(key);
        }
      }
      return drafts;
    },
    cleanupOldDrafts() {
      const drafts = this.getAllDraftsFromSessionStorage();
      if (drafts.length > 5) {  // Keep only the 5 most recent drafts
        drafts.sort((a, b) => {
          const timeA = parseInt(a.split('_').pop());
          const timeB = parseInt(b.split('_').pop());
          return timeA - timeB;
        });
        for (let i = 0; i < drafts.length - 1; i++) {
          sessionStorage.removeItem(drafts[i]);
        }
      }
    },
    checkForDraft() {
      const drafts = this.getAllDraftsFromSessionStorage();
      if (drafts.length > 0) {
        const mostRecentDraft = drafts[drafts.length - 1];
        const draftData = JSON.parse(sessionStorage.getItem(mostRecentDraft));
        const { titleKey, language } = draftData;

        ConfirmProvider.openConfirm(
          this,
          'Draft Found',
          `You have an unsaved draft for article with titleKey "${titleKey}" in ${language === 'cn' ? 'Chinese' : 'English'}. Would you like to load it?`,
          () => {
            this.article = draftData;
            this.$message.success(`Draft for "${titleKey}" (${language}) loaded successfully`);
          },
          () => {
            this.$message.info('Starting with a fresh article');
            this.resetForm();
          }
        );
      }
    },
  }
};
</script>

<style lang="css" scoped>
.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif;
  /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold', serif;
  /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold', serif;
  /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.article-manager {
  padding: 20px;
}

.article-editor {
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  object-fit: cover;
}

.el-form-item >>> .el-form-item__label {
  color: white;
}
</style>