<script lang="javascript">
import axios from 'axios';
import para from '../../paras/Paras';
import {Message} from 'element-ui';
import {isArray} from "vue-resource/src/util";
import UserService from "@/api/user/UserService";
import Optional from "@/optional/Optional";
import RouterPath from "@/router/RouterPath";
import UserPermissionService from "@/api/user/UserPermissionService";

function getRequestWithoutPermissionCheck(url, success, error) {
  UserPermissionService.isAllowedImportantApiCall(url, function () {
    axios.get(para.SERVER + url, {
      headers: {
        'content-type': 'application/json'
      }
    }).then((res) => {
      success(res.data);
    }).catch((e) => {
      processErrorWithoutRedirect(e, error);
    });
  });
}

function postRequestWithoutPermissionCheck(url, data, success, error) {
  axios.post(para.SERVER + url, JSON.stringify(data), {
    headers: {
      'content-type': 'application/json',
    }
  }).then((res) => {
    success(res.data);
  }).catch((e) => {
    processErrorWithoutRedirect(e, error);
  });
}

function getRequest(url, success, error) {
  UserPermissionService.isAllowedImportantApiCall(url, function () {
    axios.get(para.SERVER + url, {
      headers: {
        'content-type': 'application/json',
        'X-Token': UserService.getToken(),
        'X-Admin-Token': 'DEV'
      }
    }).then((res) => {
      success(res.data);
    }).catch((e) => {
      processError(e, error);
    });
  });
}

function getRequestWithParams(url, params, success, error) {
  UserPermissionService.isAllowedImportantApiCall(url, function () {
    axios.get(para.SERVER + url, {
      params: params,
      headers: {
        'content-type': 'application/json',
        'X-Token': UserService.getToken(),
        'X-Admin-Token': 'DEV'
      }
    }).then((res) => {
      success(res.data);
    }).catch((e) => {
      processError(e, error);
    });
  });
}

function getRequestWithFullUrl(url, success, error) {
  axios.get(url, {
    headers: {
      'content-type': 'application/json',
      'Access-Control-Allow-Origin' : '*'
    }
  }).then((res) => {
    success(res.data);
  }).catch((e) => {
    if (error) {
      error(e);
    }
  });
}


function postRequest(path, data, success, error) {
  UserPermissionService.isAllowedImportantApiCall(path, function () {
    axios.post(para.SERVER + path, JSON.stringify(data), {
      headers: {
        'content-type': 'application/json',
        'X-Token': UserService.getToken()
      }
    }).then((res) => {
      success(res.data);
    }).catch((e) => {
      processError(e, error);
    });
  });
}

function deleteRequest(path, success, error) {
  UserPermissionService.isAllowedImportantApiCall(path, function () {
    axios.delete(para.SERVER + path, {
      headers: {
        'content-type': 'application/json',
        'X-Token': UserService.getToken()
      }
    }).then((res) => {
      success(res.data);
    }).catch((e) => {
      processError(e, error);
    });
  });
}

function postFormData(url, formData, success, error) {
  axios.post(para.SERVER + url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Token': UserService.getToken()
    }
  }).then((res) => {
    success(res.data);
  }).catch((e) => {
    processError(e, error);
  });
}

function processError(e, error) {
  if (e.name === 'TypeError') {
    printError(e.message);
  }
  Optional.orElse(() => {
    if (isArray(e.response.data)) {
      for (const index in e.response.data) {
        setTimeout(()=>{
          let msg = e.response.data[index];
          printError(msg);
        }, 68);
      }
    } else {
      if (e.response.data.indexOf('!DOCTYPE') === -1) {
        printError(e.response.data);
      }
    }
    if (e.response.status === 401 && window.location.pathname.indexOf(RouterPath.router.login) === -1) {
      UserService.clear();
      window.location = RouterPath.basic + RouterPath.router.login;
    }
  });
  if (error) {
    error(e);
  }
}

function processErrorWithoutRedirect(e, error) {
  if (e.name === 'TypeError') {
    printError(e.message);
  }
  Optional.orElse(() => {
    if (isArray(e.response.data)) {
      for (const index in e.response.data) {
        setTimeout(()=>{
          let msg = e.response.data[index];
          printError(msg);
        }, 68);
      }
    } else {
      if (e.response.data.indexOf('!DOCTYPE') === -1) {
        printError(e.response.data);
      }
    }
  });
  if (error) {
    error(e);
  }
}

function getRequestPromise(url) {
  return axios.get(para.SERVER + url, {
    headers: {
      'content-type': 'application/json',
      'token': UserService.getToken(),
      'X-Token': UserService.getToken(),
      'username': UserService.getUserName()
    }
  });
}

function postRequestPromise(url, data) {
  return axios.post(para.SERVER + url, JSON.stringify(data), {
    headers: {
      'content-type': 'application/json',
      'token': UserService.getToken(),
      'X-Token': UserService.getToken(),
      'username': UserService.getUserName(),
    }
  });
}

function postRequestToTraCup(url, data, success, error) {
  UserPermissionService.isAllowedAndThen(UserPermissionService.all_permission.TraCupPanel, function () {
    axios.post(url, data, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      }
    }).then((res) => {
      success(res.data);
    }).catch((e) => {
      processErrorWithoutRedirect(e, error);
    });
  });
}

function printError(msg) {
  if (msg.indexOf('Track ID') > -1) {
    console.error(msg);
  } else {
    Message.error(msg);
  }
}

export default {
  getRequestWithFullUrl,
  getRequestWithoutPermissionCheck,
  postRequestWithoutPermissionCheck,
  getRequest,
  getRequestWithParams,
  postRequest,
  postFormData,
  getRequestPromise,
  postRequestPromise,
  postRequestToTraCup,
  deleteRequest,
  methods: {
    openHTML(html) {
      this.$message({
        dangerouslyUseHTMLString: true,
        message: html
      });
    }
  }
}
</script>